.page-archive {

    h2 {
        text-align: center;
        margin-top: 24px;
    }
    .footer {
        margin-top: 18px;
    }

    @include media-breakpoint-up(sm) {
        .enrollment {
            margin-top: 37px;
        }
        .players-table {
            margin-top: 30px;
        }
        h2 {
            margin-top: 18px;
        }
        .footer {
            margin-top: 50px;
        }
    }
}
