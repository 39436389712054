@import "_hosts.scss";

.players {
    &__card {
        @extend .hosts__card;

        &:last-child {
            border-bottom: 1px solid $border-on-white-color;
        }

        &__image {
            @extend .hosts__card__image;
            width: 100%;
            height: auto;
            margin-right: 0;
            float: none;
        }

        &__info {
            @extend .hosts__card__info;

            &__header {
                @extend .hosts__card__info__header;
                min-height: unset;

                h3 {
                    overflow-wrap: break-word;
                }
            }

            p {
                overflow-wrap: break-word;
            }
        }

        &__full-description {
            @extend .hosts__card__full-description;
        }

        @include media-breakpoint-up(sm) {
            border-bottom: none !important;

            &__image {
                width: 200px;
                height: 200px;
                margin-inline: auto;
                float: none;
            }

            &__info {
                margin-top: 0;
                font-size: 16px;
                line-height: 19px;

                &__header {
                    min-height: auto;
                }

                h3 {
                    font-size: 24px;
                    line-height: 28px;
                    margin-top: 20px;
                    margin-bottom: 3px;
                }

                p {
                    margin-top: 10px;
                }
            }
        }
    }
}
