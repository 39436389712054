.embla {
    position: relative;
    text-align: center;

    &__viewport {
        overflow: hidden;
        width: 100%;

        &.is-draggable {
            cursor: move;
            cursor: grab;
        }
        &.is-dragging {
          cursor: grabbing;
        }
    }
    &__wrapper {
        display: inline-block;
        max-width: 100%;
    }
    &__container {
        display: flex;
        user-select: none;
        -webkit-touch-callout: none;
        -khtml-user-select: none;
        -webkit-tap-highlight-color: transparent;
    }
    &__slide {
        position: relative;

        &__inner {
          position: relative;
          overflow: hidden;
        }
    }
    &__button {
        outline: 0;
        cursor: pointer;
        background-color: transparent;
        touch-action: manipulation;
        position: absolute;
        z-index: 1;
        top: 50%;
        transform: translateY(-50%);
        border: 0;
        width: 30px;
        height: 30px;
        justify-content: center;
        align-items: center;
        fill: #c0e1ff;
        padding: 0;

        &:disabled {
            display: none;
        }

        &__svg {
            width: 100%;
            height: 100%;
        }

        &--prev {
            left: 0;
        }

        &--next {
            right: 0;
        }
    }
}
