.invitation-card {
    padding-bottom: 16px;
    margin-bottom: 10px;
    text-align: center;
    font-size: 16px;
    line-height: 18.75px;

    @include media-breakpoint-up(sm) {
        padding-top: 51px;
        padding-bottom: 24px;
        height: 100%;
    }

    h3 {
        text-transform: uppercase;
        margin-bottom: 3px;

        @include media-breakpoint-up(sm) {
            font-size: 32px;
            line-height: 37px;
        }
    }

    p {
        margin-left: -14px;
        margin-right: -14px;
    }

    .small {
        font-size: 14px;
        margin-bottom: 9px;
        @include media-breakpoint-up(sm) {
            font-size: 16px;
            margin-bottom: 15px;
        }
    }

    .nav-link {
        padding: 6px 11px;
        span {
            @include dashed-link(#000000, $link-hover-color);
        }
        &.active {
            pointer-events: none;
            span {
                color: $primary;
                border: none;
            }
        }
    }

    .btn-primary {
        padding: 0.6rem 1.6rem;
        &.btn-wide-padding {
            padding: 0.6rem 2.7rem;
        }
        @include media-breakpoint-up(sm) {
            font-size: 24px;
            padding: 1rem 2.4rem;
            &.btn-wide-padding {
                padding: 1rem 3.5rem;
            }
        }
    }

    &__ready-popover {
        ul {
            margin-bottom: 0;
        }
        li {
            margin-bottom: 5px;
            font-size: 16px !important;
            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &__ready-link {
        @include dashed-link($primary, $link-hover-color);
        cursor: pointer;
    }

    &__price-selector {
        margin: 0 -14px;
        display: flex;
        justify-content: center;
    }

    &__price-title.small {
        margin-top: -7px;
        margin-bottom: -5px;
        @include media-breakpoint-up(sm) {
            margin-top: 8px;
            margin-bottom: 15px;
        }
    }

    &__price {
        margin-top: 17px;
        margin-bottom: 12px;

        @include media-breakpoint-up(sm) {
            margin-top: 0;
        }

        font-family: $condensed-font;
        font-size: 24px;
        b {
            font-size: 32px;
        }
    }
}
