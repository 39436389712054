.tournament-stats {

    text-align: left;

    &__cell {
        width: 90px;
        height: 45px;
        background: $white;
        font-weight: bold;
        color: $text-muted;
        text-align: center;
        display: inline-block;

        div {
            font-size: 16px;
            line-height: 19px;
        }

        a {
            font-weight: normal;
            font-size: 14px;
            line-height: 16px;
            @include dashed-link($text-muted, $link-hover-color);
        }

        &--active {
            div {
                color: $primary;
            }
        }

        &--highlighted {
            background: #ffffdd;
        }

        &--proposal {
            width: auto !important;
            text-align: left;
            padding-left: 15px;
            div {
                font-weight: normal;
            }
        }
    }

    &--mobile {

        .tournament-stats__cell {
            box-shadow: 0px 1px 8px rgba(101, 141, 177, 0.15);
            border-radius: 7px;
            margin-right: 6px;
            margin-bottom: 9px;
            padding: 5px;

            &:nth-child(3n) {
                margin-right: 0;
            }

            a {
                display: inline-block;
                height: 15px;
            }

            &.tournament-stats__cell--active {
                border: 1px solid $primary;

                div {
                    color: $primary;
                }
            }
        }
    }

    &--desktop {
        min-width: 10px + 95px * 5;
        padding-left: 10px;
        display: flex;
        flex-wrap: wrap;

        .tournament-stats__cell {
            width: 95px;
            height: 50px;
            padding-top: 5px;
        }
    }
}
