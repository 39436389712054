.mafia-app {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    min-height: 100vh;

    &__header {
    }

    &__content {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 1rem;
    }

    &__footer {

    }

}
