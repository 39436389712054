.sidemenu {

    &__header {
        padding-bottom: 10px;
    }

    &__body {
        padding-top: 0;
    }

    &__close {
        width: 18px !important;
        height: 18px !important;
        padding: 0 !important;
        margin-right: -22px !important;
        background-size: contain;
    }

    &__location {
        margin-left: -6px;
        padding-left: 14px;
        font-size: 14px;
        color: $text-muted;

        background-image: url('/img/icons/icon-location-arrow.svg');
        background-repeat: no-repeat;
        background-position: 0 4px;
        a {
            @include dashed-link($text-muted);
        }
    }

    &__button {
        width: 100%;
        padding: 0.5rem 0.6rem;
        margin-bottom: 0.5rem;

        &--outline {
            border-width: 2px;;
            font-size: 16px;
            font-weight: normal;
            text-transform: uppercase;
            color: #000000;
        }
    }

    &__account-summary {
        margin-top: 10px;
        margin-bottom: 15px;
        border-bottom: 1px solid #E0E7EC;
        .player-account-summary__player {
            margin: 0 -60px;
            padding-left: 60px;
            padding-right: 60px;
        }
    }

    &__menu {
        padding: 10px 0 0 0;
        font-family: $condensed-font;
        font-size: 16px;
        text-transform: uppercase;
        list-style: none;

        li {
            margin-bottom: 12px;
            &.active a, a:hover {
                position: relative;
                color: $orange-color;
                &::after {
                    content: url('/img/icons/icon-chevron-orange.svg');
                    position: absolute;
                    left: -13px;
                    top: 2px;
                }
            }
        }

        a {
            text-decoration: none;
            color: #000000;
        }
    }
}
