.props-item-list {
    display: grid;

    @include media-breakpoint-up(sm) {
        grid-template-columns: 1fr 1fr 1fr;
        gap: 7px;
    }
}

.props-item {
    display: flex;
    gap: 10px;
    padding: 15px 0 25px 0;
    border-bottom: 1px solid $border-on-white-color;

    @include media-breakpoint-down(sm) {
        &:last-child {
            border-bottom: none;
        }
    }

    @include media-breakpoint-up(sm) {
        padding: 15px 17px 22px 8px;
        border-radius: 12px;
        border: 1px solid $border-on-white-color;
        box-shadow: $border-on-white-box-shadow;
    }

    &__image {
        width: 150px;
        text-align: center;
        img {
            max-width: 150px;
            max-height: 254px;
        }
    }

    h4 {
        padding-top: 6px;
        margin-bottom: 3px;

        .badge {
            position: relative;
            top: -1px;
            padding: 2px 10px;
            border-radius: 20px;
            color: white;
            background: #343c47;
            font-size: 16px;
            font-weight: bold;
        }
    }

    &__description {
        margin-top: 8px;
        margin-bottom: 20px;
        font-size: 14px;
        line-height: 1.15;

        display: -webkit-box;
        -webkit-line-clamp: 8;
        -webkit-box-orient: vertical;
        overflow: hidden;

        @include media-breakpoint-up(sm) {
            height: 14 * 1.15 * 6px;
            -webkit-line-clamp: 6;
        }
    }

    &__sku,
    &__price {
        font-size: 16px;
    }

    .btn-primary {
        padding: 9px 31px;
        margin-top: 9px;
    }
}
