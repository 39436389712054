.icon-check-mark::after {
    content: '';
    display: inline-block;
    width: 10px;
    height: 6px;
    background: url(/img/icons/icon-chevron-down.svg) no-repeat center;
    @include color-filter-white;
}

.icon-geo-point::after {
    content: '';
    display: inline-block;
    width: 14px;
    height: 18px;
    background: url(/img/icons/icon-geo-point.svg) no-repeat center;
}

.icon-crown::after {
    content: '';
    display: inline-block;
    width: 22px;
    height: 22px;
    background: url(/img/icons/icon-crown.svg) no-repeat center;
}

.icon-crown-2::after {
    content: '';
    display: inline-block;
    width: 22px;
    height: 16px;
    background: url(/img/icons/icon-crown-2.svg) no-repeat center;
}

.icon-copy::after {
    content: '';
    display: inline-block;
    width: 22px;
    height: 22px;
    background: url(/img/icons/icon-copy.svg) no-repeat center;
}

.icon-check::after {
    content: '';
    display: inline-block;
    width: 22px;
    height: 22px;
    background: url(/img/icons/icon-check.svg) no-repeat center;
}
