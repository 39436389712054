.home-custom-block {
    margin-top: 40px;
    margin-bottom: 40px;
    text-align: center;

    h3 {
        font-weight: 700;
        font-size: 28px;
        color: $primary;
        text-transform: uppercase;

        @include media-breakpoint-up(sm) {
            font-size: 45px;
        }

        a {
            @include dashed-link($link-color, $link-hover-color);
        }
    }
}
