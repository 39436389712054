.subscription-card {
    background: white;
    box-shadow: 0px 4px 24px rgba(164, 196, 203, 0.25);
    border-radius: 12px;
    margin-bottom: 20px;

    font-family: $condensed-font;
    text-align: center;

    @include media-breakpoint-up(sm) {
        margin-bottom: 30px;
    }

    &__header {
        position: relative;
        color: white;
        border-top-left-radius: 12px;
        border-top-right-radius: 12px;
        width: 100%;
        aspect-ratio: 290 / 100;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        background-size: cover;

        &--black {
            background-color: black;
            background-image: url(/img/subscriptions/black.svg);
        }
        &--orange {
            background-color: orange;
            background-image: url(/img/subscriptions/orange.svg);
        }
        &--green {
            background-color: green;
            background-image: url(/img/subscriptions/green.svg);
        }
        &--blue {
            background-color: blue;
            background-image: url(/img/subscriptions/blue.svg);
        }
        &--magenta {
            background-color: magenta;
            background-image: url(/img/subscriptions/magenta.svg);
        }
    }

    &__title {
        font-size: 32px;
        line-height: 1;
        &--small {
            font-family: $font-family-sans-serif;
            font-size: 16px;
            margin-bottom: -2px;
        }
        @include media-breakpoint-up(sm) {
            font-size: 70px;
            &--small {
                font-family: $condensed-font;
                font-size: 32px;
                margin-bottom: -8px;
            }
        }
    }

    &__discount {
        position: absolute;
        top: 7px;
        right: 7px;
        padding: 6px 7px;

        font-size: 20px;
        line-height: 1;
        font-weight: bold;
        color: $text-muted;

        background: white;
        border-radius: 8px;

        @include media-breakpoint-up(sm) {
            top: 16px;
            right: 16px;
        }
    }

    &__body {
        display: grid;
        grid-template-columns: 1fr auto;
        gap: 10px;

        padding: 20px;
        border: 1px solid $border-color;
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
        border-top: none;

        @include media-breakpoint-up(sm) {
            padding: 32px 40px;
        }
    }

    &__name,
    &__price {
        font-size: 24px;
        color: $primary;

        @include media-breakpoint-up(sm) {
            font-size: 32px;
        }
    }

    &__name {
        text-align: left;
    }

    &__price {
        text-align: right;

        @include media-breakpoint-up(sm) {
            text-align: center;
        }
    }

    &__details {
        font-family: $font-family-sans-serif;
    }

    &__details,
    &__button,
    &__purchases {
        grid-column: 1 / span 2;
        @include media-breakpoint-up(sm) {
            grid-column: auto / auto;
            text-align: left;
        }
    }

    &__duration {
        margin-top: 5px;
        color: $text-muted;
        @include media-breakpoint-up(sm) {
            margin-top: 0px;
        }
    }

    &__button {
        .btn {
            padding: 13px 30px;
            font-size: 24px;
        }
    }
}
