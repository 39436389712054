.payment {
    margin: 28px 0 38px 0;

    width: 100%;
    padding: 14px 20px 14px 17px;
    border: 1px solid $border-on-white-color;
    border-radius: 9px;

    text-align: center;

    @include media-breakpoint-up(sm) {
        min-width: 300px;
        max-width: 400px;
    }

    h2 {
        margin-bottom: 1rem;
    }

    .btn {
        margin-top: 1rem;
    }
}
