.footer {
    margin-top: 35px;
    padding-bottom: 25px;

    color: $text-muted;

    @include media-breakpoint-up(sm) {
        margin-top: 45px;
    }

    a {
        color: $body-color;
        text-decoration: none;
        &:hover {
            color: $link-hover-color;
        }
    }

    &__menu {
        border-top: 1px solid $border-on-white-color;
        padding-top: 15px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        @include media-breakpoint-up(sm) {
            gap: 25px;
        }
    }

    &__menu-block {
        margin-top: 15px;
        width: 50%;

        @include media-breakpoint-up(sm) {
            width: auto !important;
        }

        h3 {
            font-weight: normal !important;
            text-align: start !important;
            color: #e0e7ec; // $text-muted;
        }

        &--legal {
            h3 {
                color: $body-color;
            }
            @include media-breakpoint-up(sm) {
                order: 6;
            }
        }

        &--legal,
        &--logo {
            width: 100%;
        }
    }

    &__menu-item {
        margin-bottom: 4px;
        font-size: 14px;
    }

    &__logo {
        img {
            width: 213px;
            height: 70px;
            filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
        }

        &--custom {
            img {
                height: 49px;
                width: auto;
                filter: none;
                @include media-breakpoint-up(sm) {
                    height: 66px;
                }
            }
        }
    }
}
