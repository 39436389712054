.flex-title {
    display: flex;
    //justify-content: start;
    align-items: center;

    > div {
        font-size: 20px;
        line-height: 23px;
        font-family: $condensed-font;
        position: relative;
        top: -3px;
        margin-left: 5px;
        //width: 100%;

        a {
            text-decoration: none;
            position: relative;
            span {
                @include dashed-link($link-color, $link-hover-color);
            }

            &::after {
                content: "";
                background: url("/img/icons/icon-chevron-collapse.svg") no-repeat center;
                display: block;
                background-size: 14px 8px;
                margin-left: 5px;
                position: absolute;
                width: 14px;
                height: 8px;
                right: -17px;
                bottom: 9px;
                opacity: 0.7;
            }
        }
    }

    &__link {
        margin-left: 20px;
        position: relative;
        top: -1px;
        flex-grow: 20;
        color: #343c47;
        &--right {
            margin-left: 0;
            text-align: right;
        }
        &:hover {
            color: $primary;
        }
    }

    &--gallery {
        > div {
            font-size: 16px;
            line-height: 19px;
            top: -4px;
            margin-right: 13px;
            text-align: right;
        }

        a::after {
            right: -14px !important;
        }
    }

    &__dropdown {
        font-size: 23px;
        .dropdown-item {
            font-family: $font-family-sans-serif;
            &::after {
                display: none !important;
            }
        }
    }

    &__pills {
        flex-grow: 2 !important;
        margin-left: 0 !important;

        a {
            &::after {
                display: none !important;
            }
        }
    }

    @include media-breakpoint-up(sm) {
        > div {
            font-size: 24px;
            line-height: 28px;
            margin-left: 16px;
            top: -2px;
            flex-grow: 1;

            a::after {
                right: -18px;
                bottom: 9px;
            }
        }

        .btn {
            padding: 0.5rem 1.9rem;
            width: 260px;
        }

        &--gallery {
            > div {
                text-align: left;
            }
        }
    }
}
