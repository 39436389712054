@include media-breakpoint-up(sm) {
    .landing-photos__gallery {
        max-width: 100%;
        display: grid;
        gap: 6px;
        grid-template-columns: repeat(6, 1fr);
    }

    .landing-photo {
        &--telegram {
            a {
                display: block;
                width: 100%;
                height: 100%;
                padding: 20px 15px 0 15px;
                background: $primary;
                text-align: center;
                color: white;
                text-decoration: none;
                &:hover {
                    text-decoration: underline;
                }
            }
            img {
                width: 50px;
            }
            span {
                display: block;
                margin-top: 10px;
            }
        }

        img.landing-photo__photo {
            max-width: 100%;
            object-fit: cover;
        }

        &:nth-child(2) {
            grid-column: span 2;
            grid-row: span 2;
            img {
                max-width: auto;
            }
        }
        &:nth-child(5),
        &:nth-child(6) {
            grid-row: span 2;
            img {
                height: 100%;
            }
        }
    }
}
