.intro {
    padding-bottom: 34px;

    &__description {
        margin-bottom: 21px;

        &__name {
            color: $dark-plate-muted-color;
            b {
                font-weight: 700;
            }
        }

        &__about {
            margin-top: -1px;
            font-family: $condensed-font;
            font-weight: 300;
            font-size: 20px;
            line-height: 1.15;
            // line-height: 23px;

            @include media-breakpoint-up(sm) {
                margin-top: 26px;
                font-size: 32px;
                // line-height: 38px;
            }

            b,
            strong {
                font-weight: bold;
            }

            .smaller {
                font-size: 1.25rem;
                line-height: 1.2;
            }
        }

        &__enroll {
            margin-top: 1.15rem;
            text-align: center;

            @include media-breakpoint-up(sm) {
                margin-top: 1.35rem;
                text-align: left;

                .btn {
                    padding: 0.9rem 2.5rem;
                    font-size: 24px;
                }
            }
        }
    }

    &__founder-remark {
        position: relative;

        @include media-breakpoint-up(sm) {
            .review-card {
                padding: 45px 60px 45px 55px;
                border-radius: 22px;
            }
        }

        .review-card__author__avatar {
            position: relative;
            top: -3px;
            @include media-breakpoint-up(sm) {
                top: -23px;
                left: 9px;
                margin-bottom: -11px;
                img {
                    max-width: 200px;
                    max-height: 200px;
                    border-radius: 50%;
                }
            }
        }

        .review-card__author__name {
            color: #ffffff;
            @include media-breakpoint-up(sm) {
                font-size: 32px;
                line-height: 37px;
                font-weight: bold;
            }
        }

        .review-card__author__description {
            color: $dark-plate-muted-color;
            @include media-breakpoint-up(sm) {
                margin-top: 13px;
            }
        }

        .review-card__review {
            display: block;
            max-height: 57px;
            overflow: hidden;
            transition: none;

            @include media-breakpoint-up(sm) {
                margin-top: 0;
                max-height: none;
                line-height: 19px;
            }

            &.show {
                max-height: 1000px;
                .intro__founder-remark__expand {
                    display: none;
                }
            }
        }

        &__expand {
            display: flex;
            align-items: flex-end;
            justify-content: center;

            @include media-breakpoint-up(sm) {
                display: none;
            }

            position: absolute;
            left: 0;
            right: 0;
            bottom: 16px;
            height: 56px;

            background: linear-gradient(360deg, #221e2a 34.78%, rgba(34, 30, 42, 0) 100%);

            img {
                filter: brightness(0) invert(1);
            }
        }
    }
}
