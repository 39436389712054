.enrollment-modal {
    &__prohibition-reason {
        font-family: $condensed-font;
        font-size: 20px;
        @include media-breakpoint-up(sm) {
            margin: 10px 0 30px 0;
            font-size: 24px;
        }
    }

    &__price-selector {
        margin-bottom: 21px;
        display: flex;
        justify-content: center;

        .nav-link {
            padding: 6px 11px;
            span {
                @include dashed-link(#000000, $link-hover-color);
            }
            &.active {
                pointer-events: none;
                span {
                    color: $primary;
                    border: none;
                }
            }
        }
    }

    &__player {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        margin-bottom: 10px;

        @include media-breakpoint-up(sm) {
            flex-wrap: nowrap;
            margin-bottom: 0;
            gap: 0.5rem;

            input {
                // width: 250px;
            }
        }
    }

    &__delete-player {
        display: block;
        width: 100%;
        text-align: center;
        margin-bottom: 20px;

        @include media-breakpoint-up(sm) {
            position: absolute;
            margin-bottom: 0;
            width: auto;
            right: -37px;
            top: 15px;
        }
    }

    &__player-price-info {
        position: relative;
    }

    &__player-price {
        margin-top: -15px;
        color: $text-muted;
        @include media-breakpoint-up(sm) {
            margin-top: 0;
            position: absolute;
            right: 0;
        }
    }

    &__use-subscription {
        margin-top: 5px;
        margin-bottom: 10px;

        @include media-breakpoint-up(sm) {
            margin-top: 0px;
        }
    }

    &__add-player {
        // margin-top: -10px;
        margin-bottom: 10px;
        @include media-breakpoint-up(sm) {
            margin-top: 0;
        }
        a {
            @include dashed-link(black, $primary);
        }
    }

    &__price {
        margin-bottom: 10px;
        font-family: $condensed-font;
        font-size: 24px;
        span {
            font-size: 32px;
            font-weight: bold;
        }
        del {
            text-decoration-color: $green-color;
        }
        .discount-price {
            color: $green-color;
        }
        .rouble {
            font-size: 24px;
            font-weight: normal;
        }
    }

    &__corporate-switches {
        display: inline-block;
        margin-top: 10px;

        @include media-breakpoint-up(sm) {
            display: flex;
            justify-content: center;
            margin-top: 0;
            margin-bottom: 10px;
        }

        .form-switch {
            display: flex;
            align-items: center;
            margin-bottom: 5px;

            margin-right: 30px;
            &:last-child {
                margin-right: 0;
            }

            input {
                height: 18px;
                width: 34px;
                margin-right: 8px;
                &:checked {
                    background-image: escape-svg($form-switch-checked-bg-image) !important;
                }

                &:focus {
                    background-image: escape-svg($form-switch-bg-image);
                    box-shadow: none;
                }
            }
        }
    }

    &__use-balance {
        display: block;
        margin: 0 0 10px 0;
        width: 100%;
    }

    &__coupon {
        &__button {
            border: none;
            background: none;
            font-size: 16px !important;
            padding: 5px 10px !important;
            span {
                @include dashed-link($text-muted, $body-color);
            }
        }

        input {
            margin: 0 auto;
            max-width: 230px;
            text-align: center;
        }
    }

    &__hr {
        width: 100%;
        font-size: 14px;
        text-align: center;
        color: $text-muted;
        background: linear-gradient(
            180deg,
            rgba(0, 0, 0, 0) calc(50% - 1px),
            $text-muted calc(50%),
            rgba(0, 0, 0, 0) calc(50% + 1px)
        );

        span {
            padding: 0 5px;
            background: linear-gradient(
                180deg,
                rgba(0, 0, 0, 0) calc(50% - 5px),
                white calc(50%),
                rgba(0, 0, 0, 0) calc(50% + 5px)
            );
        }
    }

    &__social {
        width: 100%;
        display: flex;
        justify-content: space-around;

        a {
            display: flex;
            flex-direction: column;
            align-items: center;

            text-decoration: none;
            color: $body-color;

            font-family: $condensed-font;
            font-size: 16px;

            &:hover {
                color: $primary;
            }

            @include media-breakpoint-up(sm) {
                flex-direction: row;
                font-size: 20px;
            }
        }
        img {
            margin-bottom: 5px;
            border-radius: 27px;
            box-shadow: 0px 4px 16px rgba(37, 124, 255, 0.25);

            @include media-breakpoint-up(sm) {
                margin-bottom: 0;
                margin-right: 7px;
            }
        }
    }

    &__promocode {
        margin: 30px 0 0 0;
        display: flex;
        justify-content: center;
        width: 100%;

        &__info {
            height: 45px;
            font-size: 14px;
            line-height: 45px;
        }

        .input-wrapper {
            max-width: 300px;
            margin-right: 24px;
            position: relative;
        }

        &__delete {
            width: 45px;
        }

        &--applied {
            .form-control {
                border-color: $green-color;
                color: $green-color;
                padding-right: 40px;
            }

            .icon-check {
                @include color-filter-green;
                position: absolute;
                top: 10px;
                right: 10px;
            }
        }
    }
}
