.player-account-summary {
    &__player {
        display: flex;
        align-items: center;
        background: $light-nav-bg;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    &__avatar {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
        width: 50px;
        height: 50px;
        border-radius: 25px;
        background: white;
        box-shadow: 0px 2px 6px rgba(101, 141, 177, 0.35);

        &:hover {
            background: $primary;
        }

        img {
            width: 46px;
            height: 46px;
            border-radius: 23px;
        }
    }

    &__name {
        font-size: 16px;
        line-height: 19px;
        color: black;
        text-decoration: none;
        &:hover {
            color: $primary;
        }
    }

    &__counts {
        display: flex;
        justify-content: space-between;
        padding: 13px 0;
    }

    &__count {
        font-size: 14px;
        color: $text-muted;
    }

    &__number {
        font-family: $condensed-font;
        font-size: 24px;
        line-height: 24px;
        color: black;
        margin-bottom: -3px;
    }

    &__logout {
        padding: 5px 0 15px 0;
        text-align: center;

        a {
            display: block;
        }

        @include media-breakpoint-up(sm) {
            padding: 20px 0 5px 0;
            a {
                display: inline
            }
        }

    }
}
