$audit-pair-item-width: 280px;

.audit {
    width: 100%;

    @include media-breakpoint-up(sm) {
        padding: 20px 40px 10px 40px;
        border: 1px solid $border-on-white-color;
        border-radius: 12px;
        box-shadow: 0px 4px 24px rgba(164, 196, 203, 0.25);
    }

    &__persons {
        margin-top: 1rem;
        display: flex;

        > div:first-child {
            margin-right: 1rem;
            @include media-breakpoint-up(sm) {
                min-width: $audit-pair-item-width;
                margin-right: 0;
            }
        }
    }

    &__person {
        margin-bottom: 0.5rem;
        display: flex;
        align-items: center;

        > div {
            margin-right: 0.5rem;
        }

        h3 {
            margin-bottom: 0;
        }

        img {
            max-width: 70px;
            max-height: 70px;
            border-radius: 50%;
            border: 2px solid transparent;

            &:hover {
                border: 2px solid #00baf5;
            }
        }
    }

    &__comment {
        margin: 2rem 0;
        white-space: pre-line;
    }

    &__property-pair {
        @include media-breakpoint-up(sm) {
            display: flex;
            > div {
                min-width: $audit-pair-item-width;
            }
        }
    }

    &__property {
        margin-top: 1rem;
        font-family: $condensed-font;

        &--line {
            @include media-breakpoint-up(sm) {
                > div {
                    display: inline-block;
                    margin-right: 0.5rem;
                }
            }
        }

        a {
            color: $body-color;
            &:hover {
                color: $link-hover-color;
            }
        }
    }

    &__property-label {
        font-weight: bold;
    }
}
