.user-enrollments {
    position: sticky;
    top: 0;
    z-index: 101;

    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;
    font-size: 16px;
    font-family: $condensed-font;
    line-height: 1.8;

    background: $light-nav-bg;

    &--non-sticky {
        position: relative;
        @include media-breakpoint-up(sm) {
            position: sticky;
        }
    }

    &--dark {
        color: #ffffff;
        background: $dark-plate-muted-color;

        .user-enrollment__game-link {
            color: white;
        }
    }

    &--telegram-link {
        position: relative;
        line-height: 1.2;
    }

    .btn-sm {
        padding: 0.125rem 0.5rem;
        line-height: 1.15;
        font-size: 1rem;
        font-weight: normal;
        box-shadow: none;
        border: none;
    }
}

.user-enrollment {
    display: block;
    line-height: 1.5;
    margin-top: 5px;
    &:first-child {
        margin-top: 0;
    }

    @include media-breakpoint-up(sm) {
        display: inline;
        white-space: nowrap;
        margin-top: 0px;
        margin-left: 20px;
        &:first-child {
            margin-left: 0;
        }
    }

    &__game-link {
        color: $body-color;
    }

    &__payment-notification {
        margin: 0 10px;
        white-space: nowrap;
    }
}
