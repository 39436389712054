.page-peerrating {
    &__container {
        text-align: center;
    }

    h3,
    .h3 {
        padding: 15px 0 20px 0;
    }

    .btn.btn-wide-padding {
        padding: 0.9rem 2rem;
        font-size: 24px;
    }
}
