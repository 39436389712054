.schedule-date-selector {
    max-width: fit-content;
    margin-left: auto;
    margin-right: auto;
    margin-top: 13px;

    .nav {
        flex-wrap: nowrap;
        align-items: center;
    }
    .nav-item {
        white-space: nowrap;
        text-align: center;
    }

    &__nav-pills {
        a.nav-link {
            margin-right: 10px;
            padding: 7px 10px;
            color: $text-muted;
            background: transparent;

            span {
                @include dashed_link(black, $link-color);
            }
            &.active {
                color: $text-muted;
                background: $primary;
                &:hover {
                    background: $primary;
                }
                span {
                    color: white !important;
                    border-bottom: none;
                }
            }
            &.disabled {
                span {
                    color: $text-muted;
                    border-bottom: none;
                }
            }

            @include media-breakpoint-up(sm) {
                padding: 7px 16px;
                margin-right: 0;
                background: transparent;

                span {
                    color: black;
                    border-bottom: none;
                    &:hover {
                        color: black;
                        border-bottom: none;
                    }
                }
            }
        }
    }

    &__nav-pills--with-bg {
        a.nav-link {
            background: $light-nav-bg;
            margin-right: 10px;

            &:hover {
                background: $hover-nav-bg;
            }
            span {
                border-bottom: none;
            }
        }
    }

    &__archive {
        display: none;
        @include media-breakpoint-up(sm) {
            display: list-item;
            a.nav-link span {
                @include dashed_link(black, $link-color);
            }
        }
    }

    &__enrollment {
        span {
            color: $link-color !important;
            border-bottom: 1px solid $link-color !important;
        }
    }

    &__date-long {
        font-family: $condensed-font;
        font-size: 20px;
        font-weight: 700;
    }

    &__weekday--holiday {
        color: red !important;
    }
}
