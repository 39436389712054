.page-enrollment {
    h2 {
        text-align: center;
        margin-top: 24px;
    }
    .footer {
        margin-top: 18px;
    }

    .enrollment__expand-pills {
        display: none;
    }

    @include media-breakpoint-up(sm) {
        .enrollment-title {
            position: relative;
        }

        .enrollment__expand-pills {
            display: flex;
            position: absolute;
            top: 23px;
            right: 0;
        }

        .enrollment {
            margin-top: 27px;
        }
        .schedule--desktop h3 {
            margin-top: 27px;
        }
        h2 {
            margin-top: 18px;
        }
        .footer {
            margin-top: 50px;
        }
    }
}
