.page-landing {
    h1 {
        text-align: center;
        white-space: pre-line;
        @include media-breakpoint-up(sm) {
            font-size: 32px;
            padding: 0 70px;
        }
    }

    .landing-block {
        margin-top: 28px;
        @include media-breakpoint-up(sm) {
            margin-top: 40px;
        }
        &--intro {
            margin-top: 0;
            .intro {
                margin-top: 13px;
                @include media-breakpoint-up(sm) {
                    margin-top: 25px;
                }
            }
        }
    }

    // override _game_types.scss
    .game-types {
        &__cards {
            margin-top: 0;
        }
        .embla__button {
            top: 35px;
            @include media-breakpoint-up(sm) {
                top: 75px;
            }
        }
    }

    .home-contacts {
        padding-top: 0;
    }

    footer {
        margin-top: 50px;
    }
}
