.rules-table {
    width: 100%;
    border-color: $table-border-color;
    text-align: center;
    font-size: 14px;
    line-height: 16px;

    th, td {
        line-height: 30px;
        height: 30px;
    }

    th:first-child {
        max-width: 35px;
    }

    thead {
        background: $light-nav-bg;
    }
}

.rules-text {
    font-size: 14px;
    line-height: 16px;
    padding: 0 35px 0 2px;

    div {
        margin-bottom: 10px;
    }

    strong {
        font-size: 16px;
        line-height: 19px;
        display: block;
        margin-bottom: 5px;
    }

    ul {
        padding-left: 1.45rem;
    }

    p {
        margin-bottom: 0.4rem;
    }
}