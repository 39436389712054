.peerrating {
    --rating-choice-avatar-size: 160px;
    --rating-choice-image-size: 48px;
    @include media-breakpoint-up(sm) {
        --rating-choice-avatar-size: 160px;
        --rating-choice-image-size: 58px;
    }

    max-width: 580px;
    margin-bottom: 50px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;

    @include media-breakpoint-up(sm) {
        display: flex;
        margin-bottom: 20px;
        text-align: left;
    }

    &--thomases {
        width: fit-content;
        margin-bottom: 30px;
        text-align: center;
    }

    &--host {
        margin-bottom: 50px;
        @include media-breakpoint-up(sm) {
            margin-bottom: 30px;
        }
    }

    &__avatar {
        display: inline-block;
        width: var(--rating-choice-avatar-size);
        height: var(--rating-choice-avatar-size);
        min-width: var(--rating-choice-avatar-size);
        min-height: var(--rating-choice-avatar-size);
        border-radius: 50%;
        border: 2px solid white;
        filter: drop-shadow(0px 2px 6px rgba(101, 141, 177, 0.35));

        @include media-breakpoint-up(sm) {
            margin-right: 35px;
        }

        img {
            width: 100%;
            height: auto;
            border-radius: 50%;
        }
    }

    &__body {
        padding-top: 15px;
    }

    &__name {
        margin-bottom: 10px;
        font-size: 24px;
        font-weight: 400;
        span {
            color: $text-muted;
        }
    }

    &__rate {
    }

    &__choice {
        width: var(--rating-choice-image-size);
        height: var(--rating-choice-image-size);
        margin-right: 2px;
        padding: 0;
        border: 0;
        background-color: white;
        background-size: contain;
        outline: none;

        &__image {
            width: 100%;
            display: none;
            object-fit: contain;
        }

        &:hover,
        &--active {
            .peerrating__choice__image {
                display: block;
            }
        }
    }

    &__rating-description {
        min-height: 44px;
        font-family: $condensed-font;
        font-size: 20px;
        line-height: 1.1;
        color: $text-muted;
    }

    &__tips {
        text-align: center;
        font-size: 16px;
    }

    &__tips-title {
        position: relative;
        .form-check {
            width: fit-content;
            margin-top: 15px;
            margin-inline: auto;
            @include media-breakpoint-up(sm) {
                position: absolute;
                margin-top: 0;
                right: 0;
                top: 0;
            }
        }
    }

    &__tips-amount {

        .btn {
            font-size: 24px;
            padding: 0.3rem 0.75rem;
        }
    }
}
