.tournament-awards {
    margin: 14px 0 18px 0;

    &__wrapper {
        @include media-breakpoint-up(sm) {
            display: flex;
        }
    }
}

.tournament-award {
    margin-bottom: 10px;
    padding: 13px 9px 16px 9px;
    width: 100%;
    display: flex;

    background: white;
    box-shadow: 0px 2px 12px rgba(164, 196, 203, 0.25);
    border-radius: 12px;

    @include media-breakpoint-up(sm) {
        min-width: 345px;
        padding: 15px 9px 15px 9px;
        background: $light-nav-bg;
        box-shadow: none;
        margin-right: 30px;
        margin-bottom: 0;
        &:last-child {
            margin-right: 0;
        }
    }

    &__prize {
        position: relative;
        min-width: 90px;
        min-height: 78px;
        margin-right: 12px;

        @include media-breakpoint-up(sm) {
            display: block;
            min-width: 105px;
            min-height: 84px;
        }

        .avatar {
            position: absolute;
            top: 11px;
            left: 18px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 54px;
            height: 54px;
            border-radius: 50%;
            background: white;
            box-shadow: 0px 2px 6px rgba(101, 141, 177, 0.35);
            img {
                width: 50px;
                height: 50px;
                border-radius: 50%;
            }
            @include media-breakpoint-up(sm) {
                top: 13px;
                left: 26px;
                width: 60px;
                height: 60px;
                img {
                    width: 56px;
                    height: 56px;
                }
            }
        }
    }

    &__place {
        position: absolute;
        top: 17px;
        left: 20px;
        width: 50px;

        text-align: center;
        font-size: 10px;
        color: $text-muted;
        text-transform: uppercase;

        @include media-breakpoint-up(sm) {
            top: 16px;
            left: 32px;
        }

        div {
            font-family: $condensed-font;
            font-size: 36px;
            font-weight: bold;
            line-height: 0.85;
            color: #009df5;
            @include media-breakpoint-up(sm) {
                font-size: 48px;
            }
        }
    }

    &__details {
        position: relative;
        width: 100%;
        min-height: 69px;
    }

    &__place-name {
        font-weight: bold;
        text-transform: uppercase;
        @include media-breakpoint-up(sm) {
            text-transform: none;
            font-size: 16px;
            line-height: 1;
            color: $text-muted;
        }
    }

    &__title {
        margin-top: 2px;
        font-size: 16px;
        font-weight: bold;
        a {
            color: $body-color;
            text-decoration: none;
            &:hover {
                color: $primary;
            }
        }
        @include media-breakpoint-up(sm) {
            font-size: 20px;
        }
    }

    &__description {
        margin-top: 4px;
        color: $text-muted;
        @include media-breakpoint-up(sm) {
            font-size: 14px;
        }
    }

    &__wreath {
        position: absolute;
        width: 90px;
        height: 78px;
        background-repeat: no-repeat;
        background-image: url(/img/awards/wreath.svg);
        @include media-breakpoint-up(sm) {
            width: 103px;
            height: 84px;
            background-image: url(/img/awards/wreath-lg.svg);
        }
    }
    &__wreath--place {
        top: 4px;
        @include media-breakpoint-up(sm) {
            top: 2px;
            left: 5px;
        }
    }
    &__wreath--crown {
        background-image: url(/img/awards/wreath-crown.svg);
        top: -5px;
        width: 90px;
        height: 78px;
        @include media-breakpoint-up(sm) {
            width: 103px;
            height: 93px;
            left: 5px;
            top: -5px;
            background-image: url(/img/awards/wreath-crown-lg.svg);
        }
    }
}

.tournament-award--place-1 {
    .tournament-award__place-name {
        color: $place-1-color;
    }
    .tournament-award__wreath {
        filter: brightness(0) saturate(100%) invert(71%) sepia(29%) saturate(1419%) hue-rotate(360deg) brightness(99%)
            contrast(104%);
    }
}

.tournament-award--place-2 {
    .tournament-award__place-name {
        color: $place-2-color;
    }
    .tournament-award__wreath {
        filter: brightness(0) saturate(100%) invert(89%) sepia(20%) saturate(139%) hue-rotate(153deg) brightness(96%)
            contrast(88%);
    }
}

.tournament-award--place-3,
.tournament-award--place-4,
.tournament-award--place-5,
.tournament-award--place-6,
.tournament-award--place-7,
.tournament-award--place-8,
.tournament-award--place-9,
.tournament-award--place-10,
.tournament-award--place-11 {
    .tournament-award__place-name {
        color: $place-3-color;
    }
    .tournament-award__wreath {
        filter: brightness(0) saturate(100%) invert(75%) sepia(32%) saturate(347%) hue-rotate(345deg) brightness(106%)
            contrast(80%);
    }
}
