$progress-lg-background: #f1f6f7;

.progress {
    width: 100%;
    border: 1px solid $border-on-white-color;
    border-radius: 8px;
    padding: 2px;
    position: relative;

    &__bar {
        height: 10px;
        min-width: 10px;
        border-radius: 5px;
        background: $primary;
    }

    &__indicator {
        position: absolute;
        top: 4px;
        left: 10px;
        color: white;
    }

    &__goal {
        position: absolute;
        top: 4px;
        right: 10px;
        color: $primary;
        text-shadow: -1px -1px 0 $progress-lg-background, 1px -1px 0 $progress-lg-background,
            -1px 1px 0 $progress-lg-background, 1px 1px 0 $progress-lg-background;
    }

    &--lg {
        border: none;
        border-radius: 12px;
        padding: 0;
        background: $progress-lg-background;

        .progress__bar {
            height: 20px;
            min-width: 20px;
            border-radius: 12px;
        }
        .progress__indicator {
            top: 2px;
            font-size: 14px;
        }
        .progress__goal {
            top: 2px;
            font-size: 14px;
        }
    }

    &--not-started {
        .progress__bar {
            background: transparent;
        }
        .progress__indicator {
            color: $body-color;
        }
    }

    &--completed {
        .progress__bar {
            background: #fbb600;
        }
        .progress__indicator {
            color: white;
        }
        .progress__goal {
            color: white;
            text-shadow: none;
        }
    }
}
