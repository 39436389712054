.page-games {

    h2 {
        text-align: center;
        margin-top: 24px;
    }

    @include media-breakpoint-up(sm) {
        .games {
            margin-top: 15px;
        }
        h2 {
            margin-top: 18px;
        }
        .footer {
            margin-top: 50px;
        }
    }
}
