.dark-plate {
    background: $dark-plate-bg;
    color: $dark-plate-color;

    @include media-breakpoint-up(sm) {
        min-width: $desktop-container-width + 15px + 15px;
    }

    a {
        color: $dark-plate-link-color;
    }

    h3 {
        color: $dark-plate-color;
    }

    .card {
        background: $dark-plate-bg;
        box-shadow: 0px 4px 24px rgba(203, 164, 194, 0.25)
    }
}
