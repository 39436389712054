.home-enrollment {
    padding: 20px 0 20px 0;
    text-align: center;

    @include media-breakpoint-up(sm) {
        position: relative;

        background: url(/img/enrollment-form-bg.png);
        background-size: 235px 240px;
    }

    h3 {
        text-transform: uppercase;
        @include media-breakpoint-up(sm) {
            margin-top: 15px;
            font-size: 32px;
        }
    }

    a {
        color: $primary;
        text-decoration: underline;
        &:hover {
            color: $orange-color;
        }
    }

    &__form {
        width: 220px;
        margin: 16px auto 0 auto;

        input {
            margin-bottom: 10px;
            border: none;
        }

        .btn {
            display: block;
            width: 100%;
            &.dropdown-toggle {
                border-radius: 6px !important;
                margin-bottom: 10px;
                margin-right: 0.25rem;
                background: white;
            }
        }

        @include media-breakpoint-up(sm) {
            margin-top: 38px;
            width: auto;
            display: flex;
            justify-content: center;

            input {
                width: 300px;
                margin-right: 20px;
                font-size: 32px;
            }

            .btn {
                width: 300px;
                height: 59px;
                margin-bottom: 10px;
                margin-left: -5px;
                font-size: 24px;

                &.dropdown-toggle {
                    width: auto;
                }
            }
        }
    }

    &__agreement {
        margin-top: 20px;
        text-align: center;

        @include media-breakpoint-up(sm) {
            max-width: 630px;
            margin-left: auto;
            margin-right: auto;
        }
    }
}
