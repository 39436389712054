.players-table {
    text-align: center;

    thead {
        border-bottom: 1px solid $light-nav-bg;
        font-size: 14px;
        line-height: 16px;
        color: $text-muted;
        text-transform: uppercase;

        th {
            padding-bottom: 5px;
            padding-inline: 5px;
            min-width: 60px;
            font-weight: normal;

            a {
                color: $text-muted;
                text-decoration: none;

                &:hover {
                    color: $primary;
                }
            }

            &.active {
                color: $body-color;
                font-weight: bold;
            }

            &.sorted {
                font-weight: bold;
                a {
                    color: $primary;
                }
            }

            &.players-table__head-index {
                min-width: 32px;
                text-align: left;
            }
        }
    }

    tbody tr {
        td {
            position: relative;
            padding-top: 5px;
            padding-bottom: 5px;

            &:first-child {
                // padding-top: 5px;
                padding-left: 4px;
                padding-bottom: 5px;
                @include media-breakpoint-up(sm) {
                    // padding-top: 10px;
                    padding-left: 0;
                    padding-bottom: 0;
                }
            }

            &.active {
                min-width: 100px;
                span {
                    background: $light-nav-bg;
                    position: absolute;
                    top: 2px;
                    bottom: 2px;
                    left: 7px;
                    right: 7px;
                    align-items: center;
                    display: flex;
                    justify-content: center;
                }
            }

            &.sorted {
                font-weight: bold;
            }
        }
    }

    &__head-points {
        text-transform: none;
        font-size: 16px;
        line-height: 19px;
    }

    &__body-index {
        padding-right: 10px;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        color: $text-muted;
        text-align: left;
        white-space: nowrap;
    }

    &__highlight-row {
        .players-table__participant__avatar {
            background: $primary;
        }
        .players-table__participant__name {
            color: $primary;
            &:hover {
                color: black;
            }
        }
    }

    &__participant {
        display: flex;
        align-items: center;
        text-align: left;
        position: relative;

        a {
            text-decoration: none;
        }

        &--winner {
            .icon-crown {
                position: absolute;
                top: -2px;
                left: -2px;
                outline: none;
            }
            .players-table__participant__avatar {
                border: 2px solid $primary;
                position: relative;
                img {
                    width: 62px;
                    height: 62px;
                }
            }
        }

        &__avatar {
            display: flex;
            align-items: center;
            justify-content: center;
            min-width: 70px;
            width: 70px;
            height: 70px;
            border-radius: 50%;
            background: $white;
            box-shadow: 0px 2px 6px rgba(101, 141, 177, 0.35);
            margin-right: 14px;

            img {
                width: 66px;
                height: 66px;
                border-radius: 50%;
            }
            &.place-1 {
                background: $place-1-color;
            }
            &.place-2 {
                background: $place-2-color;
            }
            &.place-3 {
                background: $place-3-color;
            }
            &.no-contact {
                background: $no-contact-color;
            }
            &.not-paid {
                background: $not-paid-color;
            }
            &.host {
                background: $host-color;
            }
            &.candidate {
                background: white;
                border: $host-color 2px dotted;
            }
            &.special {
                background: $special-color;
            }
        }

        &__name {
            display: inline-flex;
            max-width: 109px;
            overflow: hidden;
            line-height: 18px;
            font-weight: 700;
            color: $black;
        }

        &__description {
            font-size: 14px;
            line-height: 16px;
            color: $text-muted;
            word-break: break-word;

            // ellipsis на второй строке
            display: -webkit-box;
            text-overflow: ellipsis;
            overflow: hidden;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
    }

    &--tournament {
        tbody tr {
            border-bottom: 1px solid $light-nav-bg;

            &:first-child td {
                padding: 5px 0;
            }
        }

        thead th {
            padding-bottom: 2px;
        }

        .players-table__participant {
            max-width: 290px;
            width: 270px;

            .players-table__participant--winner .players-table__participant__avatar img {
                width: 52px;
                height: 52px;
            }

            .players-table__participant__avatar {
                min-width: 60px;
                width: 60px;
                height: 60px;
                margin-right: 8px;

                img {
                    width: 56px;
                    height: 56px;
                }
            }

            .players-table__participant__description {
                margin-top: 5px;
            }
        }
    }
}
