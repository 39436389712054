.corp-request {
    font-size: 16px;

    .form-label {
        font-size: 20px;
        font-family: $condensed-font;
        margin-bottom: 6px;
    }

    .form-check {
        margin-bottom: 7px;
    }

    @include media-breakpoint-up(sm) {
        display: flex;
        padding: 50px 58px 50px 80px;
        box-shadow: 0px 4px 24px rgba(164, 196, 203, 0.25);
        border-radius: 12px;
        font-family: $condensed-font;
        font-size: 20px;
    }

    &__main {
        @include media-breakpoint-up(sm) {
            position: relative;
            flex-grow: 1;
            padding-right: 30px;

            &::after {
                content: "";
                position: absolute;
                right: -1px;
                width: 1px;
                top: -5px;
                bottom: -5px;
                background: #d8e1e8;
            }
        }
    }

    &__submit-form {
        margin-top: 20px;
        text-align: center;

        @include media-breakpoint-up(sm) {
            width: 312px;
            margin-left: 24px;
        }

        > div {
            width: fit-content;
            margin-left: auto;
            margin-right: auto;
        }

        input,
        button {
            text-align: center;
        }

        .input-wrapper {
            margin: 12px auto;
            text-align: center;
            input {
                width: 100%;
            }
        }

        .dropdown-toggle {
            padding-left: 0 !important;
        }

        button.btn-primary {
            width: 190px;
            max-width: 190px;
            margin: 5px 0;
        }
    }

    &__contacts {
        max-width: 210px;
    }

    &__head {
        margin-bottom: 25px;

        @include media-breakpoint-up(sm) {
            display: flex;
            align-items: center;
        }
        h2 {
            margin-bottom: 13px;
            text-transform: uppercase;
            text-align: center;

            @include media-breakpoint-up(sm) {
                font-size: 32px;
                margin-bottom: 0;
            }
        }
    }

    &__block {
        margin-bottom: 16px;
        label {
            margin-bottom: 0;
            margin-right: 10px;
        }
    }

    &__enrollment-type {
        flex-grow: 1;
        @include media-breakpoint-up(sm) {
            padding-left: 35px;
        }

        .nav-pills {
            width: 100%;
            justify-content: space-around;
        }
        .nav-link {
            border-radius: 2rem;
            font-family: $condensed-font;
            font-size: 20px;

            @include media-breakpoint-up(sm) {
                padding: 0.5rem 1.5rem;
            }

            span {
                @include dashed-link($body-color, $link-color);
            }

            &.active {
                background: $primary;
                span {
                    color: white;
                    border-bottom: none;
                }
            }
        }
    }

    &__game-date,
    &__game-time,
    &__game-type {
        @include media-breakpoint-up(sm) {
            display: flex;
            align-items: center;
        }
    }

    &__game-date input {
        max-width: 11rem;
    }

    &__game-time input {
        max-width: 5.25rem;
    }

    #id_game_type {
        @include media-breakpoint-up(sm) {
            width: auto;
        }
    }

    &__players {
        @include media-breakpoint-up(sm) {
            display: flex;
            align-items: center;
        }

        .form-label {
            padding-top: 7px;
        }

        .form-check {
            margin-bottom: 0;
            label {
                position: relative;
                top: 1px;
            }
        }
    }

    &__players-wrapper {
        display: flex;
        @include media-breakpoint-up(sm) {
            align-items: center;
        }
    }

    &__duration-wrapper,
    &__num-players-wrapper {
        text-align: center;
        width: fit-content;
        @include media-breakpoint-up(sm) {
            display: flex;
            align-items: center;
        }
    }

    &__duration-widget,
    &__num-players-widget {
        display: flex;
        align-items: center;
    }

    #id_duration,
    #id_num_players {
        width: 70px;
        text-align: center;
        margin: 0 10px;
    }

    &__num-hosts {
        margin: 3px 0;
        color: $text-muted;
        @include media-breakpoint-up(sm) {
            margin: 0 10px;
        }
    }

    &__host-levels,
    &__payment-type {
        span {
            font-weight: bold;
            @include media-breakpoint-up(sm) {
                font-weight: normal;
            }
        }
    }

    &__price {
        font-family: $condensed-font;
        font-size: 32px;
        span {
            color: $primary;
            font-weight: bold;
        }
    }

    .privacy-note {
        color: $text-muted;
        font-size: 14px;
    }
}
