.page-player {

    h2 {
        margin-top: 0;
        line-height: 1;
    }

    h3 {
        font-weight: bold;
        text-align: center;
        @include media-breakpoint-up(sm) {
            margin: 49px 0 24px 0;
            font-size: 24px;
            font-weight: normal;
            color: $text-muted;
        }
    }

    .footer {
        margin-top: 30px;
    }

    @include media-breakpoint-up(sm) {
        .enrollment__card {
            margin-bottom: 20px;
        }

        .footer {
            margin-top: 50px;
        }
    }
}

