.reviews {

    h3 {
        text-align: center;
        text-transform: uppercase;
        @include media-breakpoint-up(sm) {
            font-size: 32px;
            font-weight: bold;
        }
    }


    &__cards {
        margin-top: 15px;

        .embla__viewport {
            padding: 10px;
        }

        .review-card {
            min-width: 240px;
            margin: 0 17px;
            text-align: left;
            display: block;

            &__date {
                margin: 15px 0 10px 0;
                font-size: 14px;
            }
        }

        @include media-breakpoint-up(sm) {
            margin-top: 30px;

            .review-card {
                min-width: 355px;
            }
        }
    }
}
