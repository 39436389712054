.nav-pills--with-bg {
    .nav-pills {
        gap: 10px;
    }

    a.nav-link {
        color: black;
        background: $light-nav-bg;

        &:hover {
            background: $hover-nav-bg;
        }

        &.active {
            background: $primary !important;
            color: white !important;
        }

    }
}
