.home-birthday-people {
    .players {
        display: flex;
        flex-wrap: nowrap;
        margin-inline: auto;
        width: fit-content;
    }
    .players__card {
        width: 124px;
        padding: 0 12px 12px 12px;

        @include media-breakpoint-up(sm) {
            min-width: 180px;
        }

        &__image {
            margin-inline: auto;
            width: 100px;
            max-width: 100px;
            max-height: 100px;
        }
    }

    .horizontal-scroller__arrow img {
        position: relative;
        top: -20px;
    }
}
