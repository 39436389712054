$player-info-container-lg-width: 1030px;

.profile {
    &__info-container {
        display: flex;
        > div {
            width: 100%;
        }
        @include media-breakpoint-up(sm) {
            width: $player-info-container-lg-width;
            margin: 40px auto 0 auto;
            gap: 12px;
            > div {
                width: 50%;
            }
        }
    }

    &__level-info {
        margin-top: 7px;
        @include media-breakpoint-up(sm) {
            margin-top: 25px;
        }
    }

    &__league {
        color: black;
    }

    &__level {
        font-family: $condensed-font;
        font-size: 20px;
        color: $primary;
    }

    &__next-level {
        margin-top: 4px;
    }

    &__experience {
        margin-top: 5px;
        font-weight: bold;
        color: black;
        @include media-breakpoint-up(sm) {
            font-size: 14px;
            font-weight: normal;
            text-align: right;
        }
    }

    &__avatar-rating-wrapper {
        margin-top: 12px;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
    }

    &__avatar {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        margin-top: 6px;
        width: 190px;
        height: 190px;
        border-radius: 50%;
        border: 4px solid white;
        filter: drop-shadow(0px 2px 17px rgba(101, 141, 177, 0.35));

        img {
            width: 182px;
            height: 182px;
            border-radius: 50%;
        }

        @include media-breakpoint-up(sm) {
            width: 430px;
            height: 430px;
            img {
                width: 422px;
                height: 422px;
            }
        }

        &__edit-btn {
            position: absolute;
            right: 0;
            bottom: 0;

            display: flex;
            align-items: center;
            justify-content: center;

            width: 50px;
            height: 50px;
            border-radius: 50%;
            background: white;

            box-shadow: 0px 2px 24px rgba(179, 203, 223, 0.5);

            img {
                width: 24px;
                height: 21px;
                border-radius: 0;
            }

            @include media-breakpoint-up(sm) {
                right: 18px;
                bottom: 10px;
                width: 80px;
                height: 80px;
                img {
                    width: 38px;
                    height: 34px;
                }
            }
        }
    }

    &__rating {
        @include media-breakpoint-up(sm) {
            display: flex;
            width: 100%;
            justify-content: space-between;
        }

        &--wide-grid {
            margin-top: 12px;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            border: 1px solid $border-on-white-color;
            border-radius: 9px;

            @include media-breakpoint-up(sm) {
                width: $player-info-container-lg-width;
                margin-left: auto;
                margin-right: auto;
                padding: 6px 0;
                grid-template-columns: repeat(6, 1fr);
            }

            .profile__rating-cell {
                width: auto;
                margin-bottom: 0;
                border: 0;
                @include media-breakpoint-up(sm) {
                    height: 90px;
                    span {
                        padding-top: 5px;
                    }
                }
            }
        }
    }

    &__rating-cell {
        display: block;
        margin-bottom: 8px;
        width: 90px;
        height: 60px;

        border: 1px solid $border-on-white-color;
        border-radius: 9px;

        text-decoration: none;
        text-align: center;
        color: $body-color;

        span {
            padding-top: 8px;
            display: block;
            font-family: $condensed-font;
            font-weight: bold;
            font-size: 24px;
            color: $primary;
        }

        @include media-breakpoint-up(sm) {
            width: 164px;
            height: 106px;
            margin-bottom: 0;
            span {
                padding-top: 15px;
                font-size: 48px;
            }
        }

        &--link {
            &:hover {
                color: $primary;
            }
        }
    }

    &__description-content {
        // ellipsis на десятой строке
        display: -webkit-box;
        text-overflow: ellipsis;
        overflow: hidden;
        -webkit-line-clamp: 10;
        -webkit-box-orient: vertical;
    }

    &__description {
        position: relative;
        margin-top: 15px;
        background: $light-nav-bg;
        border-radius: 8px;
        padding: 14px 22px;

        &::after {
            content: "";
            position: absolute;
            top: -17px;
            left: 29px;

            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 0 17px 21px;
            border-color: transparent transparent $light-nav-bg transparent;
        }

        &--editable {
            min-height: 60px;
            padding-right: 50px;
        }

        @include media-breakpoint-up(sm) {
            margin-top: 10px;
            border-top-left-radius: 0;
            &::after {
                content: "";
                position: absolute;
                top: 0;
                left: -17px;

                width: 0;
                height: 0;
                border-style: solid;
                border-width: 0 17px 17px 0;
                border-color: transparent $light-nav-bg transparent transparent;
            }
        }

        &__edit-btn {
            position: absolute;
            right: 9px;
            top: 50%;

            display: flex;
            align-items: center;
            justify-content: center;

            margin-top: -14px;
            width: 28px;
            height: 28px;
            border-radius: 50%;
            background: white;

            @include media-breakpoint-up(sm) {
                right: 15px;
            }
        }
    }

    &__info-block {
        display: flex;
        align-items: center;
        justify-content: space-between;

        margin-top: 12px;
        padding: 14px 20px 14px 17px;
        border: 1px solid $border-on-white-color;
        border-radius: 9px;

        font-size: 16px;
        text-decoration: none;
        color: $text-muted;

        &__promocodes {
            font-size: 16px;
            b {
                color: $text-muted !important;
                margin-right: 5px;
            }

            .copy-to-clipboard {
                margin-top: 7px;
                color: $primary;

                i {
                    position: relative;
                    top: 3px;
                    @include color-filter-primary;
                    &:after {
                        background-size: 18px;
                    }
                }
                a,
                span {
                    @include dashed-link($primary, $link-hover-color);
                }

                span {
                    border: 0;
                    &:hover {
                        border: 0;
                    }
                }

                &:hover {
                    cursor: pointer;
                }
            }

            .promocode-qr {
                margin-left: 30px;
                a {
                    font-weight: bold;
                    border: 0;
                    text-decoration: none;
                }
                svg {
                    width: 17px;
                    height: 17px;
                    position: relative;
                    top: -1px;
                    margin-right: 5px;
                }
            }
        }

        &--link {
            &:hover {
                color: $primary;
                b {
                    color: $primary;
                }
            }
        }

        b {
            color: $body-color;
        }

        &__num {
            font-family: $condensed-font;
            font-size: 24px;
            font-weight: bold;
            color: $primary;

            a {
                text-decoration: none;
                border-bottom: 1px dashed $primary;
                line-height: 1;
                display: inline-block;
            }
        }

        .history-btn {
            width: 100%;
            text-align: center;
            margin: 20px 0 10px 0;
        }

        .friends-report-btn,
        .profile__grades-btn {
            font-size: 16px;
            font-weight: normal;
        }

        @include media-breakpoint-up(sm) {
            padding: 17px 24px;
            font-family: $condensed-font;
            font-size: 20px;
        }

        &--desktop {
            display: none;
        }
        @include media-breakpoint-up(sm) {
            &--mobile {
                display: none;
            }
            &--desktop {
                display: flex;
            }
        }
    }

    &__enrollment-btn {
        margin: -8px 0;
        padding: 0.6rem 1.5rem;
    }

    &__awards {
        margin-top: 15px;
        margin-left: -15px;
        margin-right: -15px;
        padding-left: 10px;
        display: flex;
        flex-wrap: nowrap;
        @include overflow-no-scrollbar();

        @include media-breakpoint-up(sm) {
            width: $player-info-container-lg-width + (25px * 2);
            margin: 28px auto 0 auto;
            padding-left: 0;
            flex-wrap: wrap;
            justify-content: center;
        }
    }

    &__award {
        display: block;
        padding: 0 5px;
        text-align: center;
        color: $body-color;
        text-decoration: none;

        &:hover {
            color: $primary;
        }

        @include media-breakpoint-up(sm) {
            padding: 0 25px;
            margin-bottom: 10px;
        }

        img {
            display: block;
            margin: 0 auto 12px auto;
            width: 80px;
            height: 80px;
            @include media-breakpoint-up(sm) {
                width: 100px;
                height: 100px;
            }
        }
        div {
            white-space: nowrap;
        }
    }

    &__signed-up-games {
        margin-top: 25px;
    }

    &__archived-games {
        margin-top: 25px;
    }

    &__archived-game {
        position: relative;
        display: block;
        border: 1px solid $border-on-white-color;
        border-radius: 7px;
        padding: 11px 17px;
        margin-bottom: 10px;
        text-decoration: none;

        &::after {
            content: url("/img/icons/icon-chevron-circle-right.svg");
            position: absolute;
            right: 16px;
            top: 21px;
        }

        &__title {
            font-family: $condensed-font;
            font-size: 20px;
            color: $text-muted;

            span {
                font-weight: bold;
                color: $primary;
            }
        }

        &__details {
            margin-top: 2px;
            color: $text-muted;

            span {
                color: black;
            }
        }
    }

    &__load-more {
        margin-top: 20px;
        text-align: center;
        @include media-breakpoint-up(sm) {
            margin-top: 40px;
        }
    }

    &__extra-info {
        margin-top: 12px;
        padding: 14px 20px 14px 17px;
        border: 1px solid $border-on-white-color;
        border-radius: 9px;
    }

    &__instagram,
    &__name {
        display: flex;
        align-items: center;
        margin-top: 14px;

        &__edit-btn {
            background: $light-nav-bg;
            margin-left: 10px;
            width: 28px;
            height: 28px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    &__name__edit-btn {
        margin-bottom: -10px;
        @include media-breakpoint-up(sm) {
            margin-bottom: 10px;
        }
    }

    &__grades {
        &__title {
            text-align: center;
            font-size: 20px;
            font-weight: bold;
            font-family: $condensed-font;
        }
    }

}
