.unsubscribe {
    margin: 28px auto 68px auto;

    width: 100%;
    padding: 20px;

    border: 1px solid $border-on-white-color;
    border-radius: 9px;

    text-align: center;

    @include media-breakpoint-up(sm) {
        max-width: 500px;
    }

    h2 {
        margin-bottom: 1rem;
    }

    .btn {
        margin: 1rem;
    }
}
