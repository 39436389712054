$partner-links-gap: 10px; // реальный gap получается * 2 = 20px

.partners {

    h3 {
        text-align: center;
        text-transform: uppercase;
        @include media-breakpoint-up(sm) {
            font-size: 32px;
            font-weight: bold;
        }
    }

    &__links {
        margin-top: 24px;
        margin-left: -$partner-links-gap;
        margin-right: -$partner-links-gap;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;

        @include media-breakpoint-up(sm) {
            margin-top: 41px;
        }
    }

    &__link {
        display: block;
        height: 38px;
        margin-bottom: 22px;
        margin-left: $partner-links-gap;
        margin-right: $partner-links-gap;

        img {
            max-height: 38px;
            width: auto;
        }

        @include media-breakpoint-up(sm) {
            height: 54px;
            img {
                max-height: 54px;
            }
        }
    }

    &__link__placeholder {
        display: block;
        width: 140px;
        height: 100%;
        border-radius: 27px;

        background: $light-nav-bg;

        @include media-breakpoint-up(sm) {
            width: 198px;
            height: 100%;
            border-radius: 27px;    
        }
    }
}
