.home-awards {
    margin-top: 15px;
    @include media-breakpoint-up(sm) {
        display: flex;
    }
}

.home-award {
    margin-bottom: 10px;
    display: flex;
    padding: 11px 9px 7px 9px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 12px;
    width: 100%;

    @include media-breakpoint-up(sm) {
        flex-direction: column;
        align-items: center;
        height: 100%;
        min-height: 287px;
        padding: 21px 40px 19px 40px;
        background: linear-gradient(180deg, rgba(229, 196, 249, 0.1) 0%, rgba(129, 96, 148, 0.1) 100%);
        margin-right: 30px;
        &:last-child {
            margin-right: 0;
        }
    }

    &__prize {
        position: relative;
        top: 3px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 5px;

        width: 91px;
        height: 67px;
        min-width: 91px;
        min-height: 67px;

        @include media-breakpoint-up(sm) {
            top: 0;
            width: 216px;
            height: 155px;
            min-width: 216px;
            min-height: 155px;
        }
    }

    &__wreath {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        &::before,
        &::after {
            content: "";
            position: absolute;
            top: -1px;
        }
        &::before {
            left: 0;
            background-image: url(/img/awards/wreath-left.svg);
            width: 33px;
            height: 69px;
        }
        &::after {
            right: 0;
            background-image: url(/img/awards/wreath-right.svg);
            width: 33px;
            height: 69px;
        }

        @include media-breakpoint-up(sm) {
            &::before {
                background-image: url(/img/awards/wreath-xl.svg);
                width: 216px;
                height: 155px;
            }
            &::after {
                display: none;
            }
        }
    }

    &__icon {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 54px;
        height: 54px;
        border-radius: 50%;
        border: 2px solid white;

        img {
            width: 50px;
            height: 50px;
            border-radius: 50%;
        }
        @include media-breakpoint-up(sm) {
            position: relative;
            top: -3px;
            width: 136px;
            height: 136px;
            img {
                width: 132px;
                height: 132px;
            }
        }
    }

    &__place {
        div {
            color: $orange-color;
            font-family: $condensed-font;
            font-size: 48px;
            font-weight: bold;
            line-height: 0.85;
        }
    }

    &__details {
        margin-left: 10px;
        @include media-breakpoint-up(sm) {
            text-align: center;
        }
    }

    &__place-name {
        font-weight: bold;
        @include media-breakpoint-up(sm) {
            // font-size: 20px;
            font-size: 18px;
        }
    }

    &__title {
        margin: 3px 0;
        color: white;
        font-size: 16px;
        font-weight: bold;
        @include media-breakpoint-up(sm) {
            margin-bottom: 5px;
            // font-size: 20px;
            font-size: 18px;
        }
    }

    &__description {
        color: $home-tournament-dim-color;
        @include media-breakpoint-up(sm) {
            font-size: 14px;
        }
    }
}

.home-award--place-1 {
    .home-award__place-name {
        color: $place-1-color;
    }
    .home-award__wreath {
        filter: brightness(0) saturate(100%) invert(71%) sepia(29%) saturate(1419%) hue-rotate(360deg) brightness(99%)
            contrast(104%);
    }
}

.home-award--place-2 {
    .home-award__place-name {
        color: $place-2-color;
    }
    .home-award__wreath {
        filter: brightness(0) saturate(100%) invert(89%) sepia(20%) saturate(139%) hue-rotate(153deg) brightness(96%)
            contrast(88%);
    }
}

.home-award--place-3 {
    .home-award__place-name {
        color: $place-3-color;
    }
    .home-award__wreath {
        filter: brightness(0) saturate(100%) invert(75%) sepia(32%) saturate(347%) hue-rotate(345deg) brightness(106%)
            contrast(80%);
    }
}
