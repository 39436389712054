.header {
    display: flex;
    align-items: center;
    padding-top: 7px;

    @include media-breakpoint-up(sm) {
        padding-top: 14px;
    }

    &--dark {
        .header__hamburger {
            filter: brightness(0) invert(1);
        }
        .header__location a {
            span {
                @include dashed-link($dark-plate-color, $orange-color);
            }
            &::after {
                filter: brightness(0) invert(1);
            }
        }
        .header__menu a {
            color: $dark-plate-color;
        }
        .header__login-btn {
            background: $dark-plate-muted-color;
            color: $dark-plate-color !important;
        }
    }

    &__hamburger {
        padding-right: 8px;

        @include media-breakpoint-up(sm) {
            display: none;
        }
    }

    &__logo {
        padding-top: 7px;
        flex-grow: 1;
        @include media-breakpoint-up(sm) {
            flex-grow: unset;
            padding-top: 9px;
            padding-left: 4px;
        }

        img {
            width: 115px;
            height: 38px;
            filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

            @include media-breakpoint-up(sm) {
                width: 198px;
                height: 65px;
            }
        }

        &--custom {
            padding-top: 0;
            @include media-breakpoint-up(sm) {
                padding-top: 0;
            }

            img {
                width: auto;
                height: auto;
                max-height: 38px;
                filter: none;

                @include media-breakpoint-up(sm) {
                    width: auto;
                    height: auto;
                    max-height: none;
                }
            }
        }
    }

    &__location {
        font-family: $condensed-font;
        font-size: 16px;
        white-space: nowrap;
        font-weight: bold;

        @include media-breakpoint-up(sm) {
            flex-grow: 1;
            padding-left: 27px;
            font-size: 24px;
        }

        a {
            text-decoration: none;
            span {
                @include dashed-link($headings-color, $orange-color);
                text-transform: uppercase;
            }

            &::after {
                content: url("/img/icons/icon-chevron-down.svg");
                margin-left: 5px;
                position: relative;
                top: -1px;
            }
        }
    }

    &__menu {
        display: none;
        margin-bottom: 0;
        padding: 0;

        font-family: $condensed-font;
        font-size: 14px;
        font-weight: bold;
        text-transform: uppercase;

        @include media-breakpoint-up(sm) {
            display: flex;
            align-items: center;
            list-style: none;
            width: 100%;
        }

        a {
            text-decoration: none;
            color: $headings-color;
        }

        li {
            margin-left: 15px;
            white-space: nowrap;

            &.active a,
            a:hover {
                color: $orange-color;
                border-bottom: 1px solid $orange-color;
            }
        }

        &__avatar {
            img {
                width: 32px;
                height: 32px;
                border-radius: 16px;
                box-shadow: 0px 2px 6px rgba(101, 141, 177, 0.35);
            }
        }
    }

    &__shares-info {
        @include media-breakpoint-down(sm) {
            text-decoration: none;
            color: $body-color;
        }
    }

    &__login-btn {
        margin-left: 6px;
        padding: 0.16rem 0.65rem;
        background: $light-nav-bg;
        border-bottom: none !important;
        font-family: $font-family-sans-serif;
        font-size: 16px;
        font-weight: normal;
        text-transform: none;
        color: $body-color !important;

        a.active,
        &:hover {
            color: $dark-plate-color !important;
            background: shift-color($dark-plate-muted-color, 20%);
        }
    }

    &__account-summary {
        width: $popover-max-width;
        .player-account-summary__player {
            margin-top: -$popover-header-padding-y * 2;
            margin-left: -$popover-header-padding-x;
            padding-left: $popover-header-padding-x;
            margin-right: -$popover-header-padding-x;
            padding-right: $popover-header-padding-x;
        }
        .player-account-summary__counts {
            margin-bottom: -$popover-header-padding-y * 2;
        }
        &--dark {
            box-shadow: 0px 1px 16px black !important;
        }
    }
}
