.promo-game {
    color: black;

    &__head {
        background-size: cover;
        min-height: 85px;

        text-shadow: 0px 2px 8px rgba(0, 0, 0, 0.75);

        h1,
        h2 {
            color: inherit;
            font-weight: 700;
            margin: 0;
        }

        h1 {
            font-size: 34px;
            line-height: 1;
        }
        h2 {
            font-size: 16px;
        }

        @include media-breakpoint-up(sm) {
            min-height: 280px;
            border-radius: 12px;

            h1 {
                font-size: 64px;
            }
            h2 {
                font-size: 32px;
            }
        }
    }

    &__location {
        font-size: 24px;
        margin: 1.5rem 0 1rem 0;
        color: black;

        @include media-breakpoint-up(sm) {
            font-size: 32px;
            margin: 2rem 0 1.5rem 0;
        }
    }

    &__report-head {
    }

    &__report-head__details {
        > div {
            margin-bottom: 10px;
        }
        @include media-breakpoint-up(sm) {
            display: flex;
            font-size: 14px;

            > div {
                width: 25%;
                &:nth-child(2) {
                    width: 50%;
                    margin-left: 30px;
                    margin-right: 30px;
                }
            }
        }
    }

    &__participants {
        .h3 {
            color: black;
        }

        @include media-breakpoint-up(sm) {
            padding-top: 30px !important;
            padding-bottom: 30px !important;
            .h3 {
                font-size: 32px;
            }
            &__count {
                font-family: $condensed-font;
                font-size: 24px;
            }
        }

        &__participant {
            padding: 5px;
            &--mobile-hidden {
                display: none;
            }
            @include media-breakpoint-up(sm) {
                max-width: 106px;
                padding: 6px 8px;
                &--mobile-hidden {
                    display: block;
                }
                &--desktop-hidden {
                    display: none;
                }
                .avatar img {
                    width: 90px;
                    height: 90px;
                }
            }
        }

        &__more {
            margin: 5px;
            display: flex;
            width: 66px;
            height: 66px;
            color: #231c1b;
            border-radius: 50%;
            border: 1px solid rgba(0, 0, 0, 0.1);
            background: #f4f4f4;
            box-shadow: 0 4px 10px 10px rgba(202, 164, 203, 0.05);
            font-family: $condensed-font;
            .h2 {
                margin: 0;
                color: $primary;
            }
            small {
                font-size: 9px;
                position: relative;
                top: -2px;
            }

            @include media-breakpoint-up(sm) {
                display: none;
            }
        }
    }

    &__detail {
        color: black;
        hr {
            display: block;
            margin: 1.5rem 2rem 2rem 2rem;
        }
    }

    &__detail-image {
        text-align: center;
        img {
            // max-width: 230px;
            max-width: 100%;
            height: auto;
            // border-radius: 12px;
            border-radius: 12px;
        }
    }

    &__detail-title {
        text-align: center;
        margin-top: 1rem;
    }

    &__detail-description {
        text-align: center;
    }

    @include media-breakpoint-up(sm) {
        &__detail {
            display: flex;
            align-items: center;
            justify-content: space-between;
            // margin: 32px 73px;
            margin: 32px 173px;
            hr {
                display: none;
            }
            &:nth-child(even) {
                .promo-game__detail-image {
                    order: 2;
                }
            }
        }
        &__detail-image {
            text-align: center;
            img {
                // max-width: 490px;
                max-width: 390px;
            }
        }
        &__detail-text {
            // width: 490px;
            width: 390px;
        }
        &__detail-title {
            margin-top: 0;
            font-size: 32px;
            text-align: left;
        }
        &__detail-description {
            text-align: left;
        }
    }

    &__timeline {
        @include media-breakpoint-up(sm) {
            display: flex;
        }

        &__time {
            .badge {
                width: 70px;
                height: 34px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            @include media-breakpoint-up(sm) {
                position: relative;
                min-width: 80px;
                &::after {
                    content: "";
                    position: absolute;
                    top: 35px;
                    bottom: 0;
                    left: 34px;
                    border-left: 1px dashed #96a4aa;
                }
            }
        }

        &__description {
            @include media-breakpoint-up(sm) {
                min-width: 280px;
                max-width: 280px;
            }
        }

        &__media {
            display: flex;
            overflow-x: scroll;
            scrollbar-width: none;
            margin-right: -15px;
            @include media-breakpoint-up(sm) {
                flex-wrap: wrap;
                overflow-x: unset;
                margin-right: 0;
            }
            img,
            video {
                border-radius: 10px;
                margin-right: 10px;
                margin-bottom: 10px;
                max-height: 220px;
                width: auto;
            }

            &__video {
                position: relative;
                &::after {
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    width: 45px;
                    height: 50px;
                    margin-left: -23px;
                    margin-top: -25px;
                    content: url("/img/icons/triangle.svg");
                    opacity: 0.5;
                }
                &:hover {
                    &::after {
                        opacity: 0.7;
                    }
                }

                &--player-started::after {
                    display: none;
                }
            }
        }
    }

    &__next-game {
        .h1 {
            font-family: "Trajan Pro 3", sans-serif;
            font-weight: 400;
            font-style: normal;
            font-size: 26px;
            line-height: 0.85;
            color: #ff596d;
        }
        @include media-breakpoint-up(sm) {
            .h1 {
                font-size: 60px;
            }
            .h3 {
                font-size: 32px;
            }
        }
    }
}
