.input-group--datepicker {
    max-width: 200px;
    min-width: 120px;

    .input-group-text {
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        cursor: pointer;

        &--lg {
            border-radius: 6px;
        }

    }

    .icon-calendar {
        width: 16px;
        height: 16px;
        position: relative;
        top: -5px;
        left: -5px;

        &::after {
            content: url("/img/icons/icon-calendar.svg");
            position: absolute;
            left: 0px;
            top: 0px;
        }
    }

    .react-datepicker__input-container input {
        display: block;
        width: 100%;
        height: 38px;
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        line-height: 1.5;
        border: 1px solid #ced4da;
        appearance: none;
        border-radius: 0.25rem;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        outline: none;
        outline-offset: 0;

        &.form-control-lg {
            padding: 0.625rem 1rem;
            font-size: 1.25rem;
            border-radius: 6px;
        }
    }

    @include media-breakpoint-up(sm) {
        min-width: 150px;
    }
}
