.page-tournament {

    .flex-title {
        margin-top: 24px;
    }

    @include media-breakpoint-up(sm) {
        .tournament {
            margin-top: 30px;
        }
        .flex-title {
            margin-top: 18px;
        }
        .footer {
            margin-top: 50px;
        }
    }
}
