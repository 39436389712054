.game-type-selector {

    &--centered {
        max-width: fit-content;
        margin-left: auto;
        margin-right: auto;
    }

    &__control {
        margin-right: -15px;
        height: 31px;

        overflow: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
        }

        @include media-breakpoint-up(sm) {
            margin-right: 0;
        }

        .nav {
            flex-wrap: nowrap;
        }
        .nav-item {
            white-space: nowrap;
        }
    }

    &__nav-pills {
        a.nav-link {
            margin-right: 10px;
            padding: 7px 10px;
            color: $text-muted;
            background: $light-nav-bg;

            span {
                color: black;
            }
            &.active {
                color: $text-muted;
                span {
                    color: $link-color;
                    border-bottom: none;
                }
            }

            @include media-breakpoint-up(sm) {
                padding: 7px 16px;
                margin-right: 0;
                background: transparent;

                span {
                    @include dashed_link(black, $link-color);
                }
            }
        }

    }

    &--dark {
        color: $home-tournament-dim-color;
        .game-type-selector__nav-pills {
            a.nav-link {
                color: $home-tournament-dim-color;
                background: transparent;

                span {
                    color: white;
                    @include dashed_link(white, white);
                }

                &.active {
                    background: $light-nav-bg;
                    span {
                        color: $body-color;
                        border-bottom: none;
                    }
                }
            }
        }
    }

}
