.more-participants-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    border-radius: 50%;
    color: white;
    background: #21072b;
    font-family: $condensed-font;
    
    cursor: pointer;

    &--mobile {
        @include media-breakpoint-up(sm) {
            display: none;
        }
    }

    &--desktop {
        display: none;
        @include media-breakpoint-up(sm) {
            display: flex;
        }
    }

    .h2 {
        margin: 0;
        color: $primary;
    }

    small {
        font-size: 9px;
        position: relative;
        top: -3px;
    }
}
