$home-carousel-img-size: 184px;
$home-carousel-img-lg-size: 250px;

.home-carousel {

    @include media-breakpoint-up(sm) {
        min-width: $desktop-container-width + 15px + 15px;
    }

    a.home-carousel__card {

        // `position: relative; display: block` и размер для <a> задаётся для того,
        // чтобы можно было использовать next/image с `layout="fill"`
        position: relative;
        display: block;
        // min-width: $home-carousel-img-size;
        // max-width: $home-carousel-img-size;
        min-height: $home-carousel-img-size;
        max-height: $home-carousel-img-size;

        img {
            // max-width: $home-carousel-img-size;
            max-height: $home-carousel-img-size;
        }

        @include media-breakpoint-up(sm) {
            // min-width: $home-carousel-img-lg-size;
            // max-width: $home-carousel-img-lg-size;
            min-height: $home-carousel-img-lg-size;
            max-height: $home-carousel-img-lg-size;
            img {
                // max-width: $home-carousel-img-lg-size;
                max-height: $home-carousel-img-lg-size;
            }
        }

    }
}
