.photo-gallery {

    &__date {
        font-size: 20px;
        line-height: 23px;
        font-family: $condensed-font;
        margin-bottom: 7px;
        margin-top: 25px;

        &:first-child {
            margin-top: 0;
        }
    }

    &__album {
        background: $white;
        padding: 10px 14px 5px 14px;
        margin: 0 auto 15px auto;
        max-width: 290px;
        @include border-with-shadow;

        &:hover {
            border-color: $primary;
            box-shadow: $border-primary-box-shadow;
        }

        &__header {
            font-size: 16px;
            line-height: 19px;
            color: $body-color;
            margin-bottom: 9px;
            position: relative;

            span {
                color: $text-muted;

                &:before {
                    content: '/';
                    display: inline-block;
                    margin: 0 4px;
                }
            }
        }

        &__photos {
            overflow: hidden;
            width: 100%;
            position: relative;
            text-decoration: none;
            display: block;

            &:after {
                display: block;
                content: '';
                position: absolute;
                top: 0;
                left: 1px;
                height: 100%;
                width: 100%;
                background: rgb(246,0,0);
                background: linear-gradient(90deg, rgba(246,0,0,0) 80%, rgba(255,255,255,1) 100%);
            }

            &__wrapper {
                width: 370px;
                display: flex;

                div {
                    width: 241px;
                    min-width: 241px;

                    img {
                        width: 110px;
                        height: auto;
                        margin-bottom: 10px;
                        margin-right: 7px;
                    }

                    &:last-child {
                        min-width: 150px;
                    }
                }
            }
        }
    }

    @include media-breakpoint-up(sm) {
        &__date {
            font-size: 24px;
            line-height: 28px;
            margin-bottom: 22px;
            margin-top: 22px;
        }

        &__album {
            padding: 13px 24px 15px 24px;
            max-width: 100%;

            &__header {
                font-size: 24px;
                line-height: 28px;
                font-family: $condensed-font;
                margin-bottom: 7px;

                span {
                    font-size: 16px;
                    line-height: 19px;
                    width: 102%;
                    text-align: right;
                    position: absolute;
                    right: 0;
                    color: $body-color;
                    font-family: $font-family-sans-serif;
                    padding-top: 6px;

                    &:before {
                        display: none;
                    }
                }
            }

            &--wide {
                display: block;
                max-width: 100%;
                margin-right: 0;

                .photo-gallery__album__photos {

                    &:after {
                        background: linear-gradient(90deg, rgba(246,0,0,0) 95%, rgba(255,255,255,1) 100%);
                    }

                    &__wrapper {
                        width: 1100px;
                        display: flex;

                        div {
                            width: auto;
                            min-width: auto;
                            margin-right: 0;
                            display: flex;

                            img {
                                min-width: 230px;
                                margin-right: 10px;
                            }

                            &:first-child {
                                width: auto;
                                img:nth-child(2n) {
                                    margin-left: 0;
                                }
                            }
                        }
                    }
                }
            }

            &--compact {
                display: block;
                max-width: 100%;
                margin-right: 0;

                .photo-gallery__album__photos {
                    &:after {
                        background: linear-gradient(90deg, rgba(246,0,0,0) 90%, rgba(255,255,255,1) 100%);
                    }

                    &__wrapper {
                        width: 710px;

                        &__first {
                            width: 230px;
                            max-height: 170px;
                            margin-right: 10px;
                        }
                    }
                }
            }
        }
    }
}
