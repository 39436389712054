.schedule {
    &--mobile {
        text-align: center;

        @include media-breakpoint-up(sm) {
            display: none;
        }

        h3 {
            font-style: 20px;
        }
    }

    &--desktop {
        display: none;

        @include media-breakpoint-up(sm) {
            display: block;
        }

        h3 {
            margin-top: 35px;
            text-align: center;
            text-transform: uppercase;
            font-size: 32px;
            line-height: 37px;
        }
    }

    &__title {
        margin-top: 1rem;
        text-transform: uppercase;
    }

    &__participant-count {
        padding: 1px 9px;
        border-radius: 10px;
        background: $primary;
        font-size: 14px;
        color: #ffffff;
        white-space: nowrap;
        &::before {
            content: url("/img/icons/icon-user-sm.svg");
            width: 9px;
            height: 10px;
            margin-right: 5px;
            filter: brightness(0) invert(1);
        }
    }

    &__card {
        margin-top: 10px;
        padding-top: 18px;
        padding-bottom: 5px;

        text-align: center;
        position: relative;
        font-size: 16px;
        line-height: 19px;

        border-top: 1px solid #dfedf0;

        &__participant-count {
            position: absolute;
            top: 19px;
            right: 0;
            padding: 1px 9px;
            border-radius: 10px;
            background: $primary;
            font-size: 14px;
            color: #ffffff;

            &::before {
                content: url("/img/icons/icon-user-sm.svg");
                width: 9px;
                height: 10px;
                margin-right: 5px;
                filter: brightness(0) invert(1);
            }
        }

        &__date {
            a {
                position: relative;
                text-decoration: none;
                span {
                    color: #000000;
                    border-bottom: 1px dashed #000000;
                }

                &::after {
                    position: absolute;
                    left: -20px;
                    top: 0px;
                    content: url("/img/icons/icon-chevron-collapse.svg");
                }

                &.collapsed {
                    margin-bottom: -20px;
                }

                &.collapsed::after {
                    position: absolute;
                    left: -15px;
                    top: 3px;
                    content: url("/img/icons/icon-chevron-expand.svg");
                }
            }
        }

        &__price {
            margin-bottom: 3px;
            position: relative;
            left: 10px;
        }

        &__game-feature,
        &__host-grade,
        &__host-feature {
            color: $text-muted;
        }

        &__game-feature {
            cursor: pointer;
            &--collapsed {
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;
            }
        }

        &__host-grade {
            white-space: nowrap;
        }

        &__btn-enroll {
            margin-top: 8px;
            margin-bottom: 8px;
        }

        .schedule__participants {
            margin-top: 5px;
        }

        &--enrollment {
            .schedule__card__price,
            .schedule__card__table {
                margin-bottom: 3px;
                position: relative;
                width: 140px;
                height: 30px;
                line-height: 30px;
                background-color: $light-nav-bg;
                border-radius: 15px;
                display: inline-block;
                vertical-align: top;
                margin-top: 11px;
                text-align: center;
            }

            .info-button {
                line-height: 18px;
                position: absolute;
                right: 7px;
                top: 6px;
                outline: none;
            }

            .schedule__card__price {
                padding-right: 30px;
                padding-left: 10px;
                width: auto;
                left: 0;
            }

            .schedule__card__table {
                padding-right: 7px;

                .icon-check-mark::after {
                    position: absolute;
                    top: 6px;
                    left: 4px;
                }
            }

            .schedule__card__host {
                display: flex;
                align-items: center;
                max-width: 285px;
                margin: 8px auto 17px auto;

                .schedule__card__host__info {
                    font-size: $font-size-root;
                    text-align: left;
                    line-height: 16px;
                    margin-left: 14px;

                    strong {
                        color: $black;
                    }
                }

                img {
                    width: 66px;
                    height: 66px;
                    border-radius: 33px;
                }

                &__club {
                    img {
                        width: 20px;
                        height: 20px;
                        border-radius: 10px;
                    }
                }

                a {
                    background: $white;
                    padding: 2px;
                    border-radius: 50%;
                    box-shadow: 0px 2px 6px rgba(101, 141, 177, 0.35);
                    width: 70px;
                    height: 70px;
                }
            }

            .schedule__card__btn-enroll {
                margin-top: 13px;
                display: block;
                margin-left: auto;
                margin-right: auto;
            }
        }
    }

    &__table {
        margin-top: 30px;
        width: 100%;

        tr.schedule__table__tr {
            th {
                padding: 11px;
                text-align: center;
                font-weight: normal;
                margin-right: 1px;
                background: $light-nav-bg;
                border-right: 3px solid #ffffff;
                &:last-child {
                    border-right: none;
                }
            }
            td {
                padding: 18px 15px 17px 15px;
                text-align: center;
                vertical-align: middle;

                &:first-child {
                    white-space: nowrap;
                }
            }
        }

        tbody {
            tr.schedule__table__tr {
                border-top: 1px solid #d2dcdf;
                &:first-child {
                    border-top: none;
                }
            }
        }

        .schedule__participants {
            min-width: 315px;
            // max-width: 320px;
            justify-content: start;

            &--archive {
                max-width: revert;
            }
        }

        .info-button {
            position: relative;
            top: -2px;
        }
    }

    &__expand-link {
        position: relative;
        top: -15px;
        padding: 0;
        border: none;
        background: transparent;

        @include dashed-link($text-muted, $link-hover-color);
    }

    .enrollment__card .schedule__expand-link {
        top: 30px;
        left: -17px;
    }

    &__host {
        display: flex;
        align-items: center;

        &__avatar {
            display: flex;
            align-items: center;
            justify-content: center;

            width: 70px;
            height: 70px;
            border-radius: 35px;
            border: 2px solid $light-nav-bg;
            &:hover {
                border: 2px solid $btn-primary-hover-bg;
            }

            img {
                max-width: 66px;
                max-height: 66px;
                border-radius: 33px;
            }
            @include media-breakpoint-up(sm) {
                width: 70px;
                height: 70px;
                border-radius: 35px;

                img {
                    max-width: 66px;
                    max-height: 66px;
                    border-radius: 33px;
                }
            }
        }

        &__name {
            // margin-left: 10px;
            color: black;
            text-decoration: none;
            &:hover {
                color: $primary;
            }
        }

        &__grade {
            margin-left: 10px;
            color: $text-muted;
            white-space: nowrap;
        }
    }

    &__game-link,
    .game-place {
        @include dashed-link($primary, black);
    }

    &__participation-details {
    }

    &__participation-feature {
        cursor: pointer;
        &--collapsed {
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }
    }

    &__participation-numbers {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    &__participation-count {
        padding-left: 10px;
    }

    &__participants {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        a {
            display: flex;
            align-items: center;
            justify-content: center;

            margin-right: 2px;
            margin-bottom: 4px;
            width: 70px;
            height: 70px;
            border-radius: 35px;
            border: 2px solid $light-nav-bg;
            &:hover {
                border: 2px solid $btn-primary-hover-bg;
            }

            &.place-1 {
                border-color: $place-1-color;
            }
            &.place-2 {
                border-color: $place-2-color;
            }
            &.place-3 {
                border-color: $place-3-color;
            }
            &.no-contact {
                border-color: $no-contact-color;
            }
            &.not-paid {
                border-color: $not-paid-color;
            }
            &.host {
                border-color: $host-color;
            }
            &.candidate {
                border-color: $host-color;
                border-style: dotted;
            }
            &.special {
                border-color: $special-color;
            }
        }
        img {
            max-width: 66px;
            max-height: 66px;
            border-radius: 33px;

            &.icon {
                border-radius: 0;
            }
        }

        &__btn-enroll {
            border: none !important;
            background: $primary;

            &:hover {
                background: $btn-primary-hover-bg;
            }
            &--placeholder {
                background: $light-nav-bg;
                pointer-events: none;
            }
            &--text {
                width: 100% !important;
                border: none !important;
            }
            &--prohibited {
                background: $orange-color;

                &:hover {
                    background: $orange-hover-color;
                }
            }
        }
    }

    &__toggle-participants-table {
        margin-top: 10px;
        padding: 0;
        border-left: none;
        border-right: none;
        border-top: none;
        @include dashed-link($body-color, $body-color);
        color: $body-color;
        background: transparent;
        outline: none;
    }

    &__enrollment-link {
        padding: 20px 0 10px 0;
        text-align: center;
        text-transform: uppercase;
    }

    &__price {
        color: $primary;
    }

    &__price-popover {
        font-size: 16px;
        line-height: 19px;
    }
}
