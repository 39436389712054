.page-tournament {

    .tournament {

        &__rules-link {
            font-size: 14px;
            line-height: 16px;
            display: flex;
            justify-content: space-between;
            margin-top: 3px;

            span {
                color: $text-muted;
            }

            a {
                @include dashed-link($link-color, $link-hover-color);
            }
        }

        &__participants {
            margin-top: 15px;

            .row {
                padding-top: 10px;
                padding-bottom: 17px;
                border-top: 1px solid $border-on-white-color;

                .col-2 {
                    text-align: center;
                }
            }

            &__avatar {
                background: $white;
                padding: 2px;
                border-radius: 50%;
                box-shadow: 0px 2px 6px rgba(101, 141, 177, 0.35);
                width: 40px;
                height: 40px;
                display: inline-block;
                // margin-top: 5px;

                img {
                    width: 36px;
                    height: 36px;
                    border-radius: 50%;
                }
            }

            &__title {
                font-weight: bold;
                display: flex;

                div {
                    font-size: 20px;
                    line-height: 23px;
                    color: $orange-color;
                    font-family: $condensed-font;
                }

                p {
                    font-size: 16px;
                    line-height: 19px;
                    flex-grow: 1;
                    padding-top: 2px;
                    margin-bottom: 5px;

                    span {
                        color: $primary;
                        font-size: 16px;
                        line-height: 19px;
                        padding-right: 5px;
                    }
                }
            }

            &__name {
                text-decoration: none;
                color: #000;
                &:hover {
                    color: $link-hover-color;
                }
            }

            &__info {
                font-size: 14px;
                line-height: 16px;
                color: $text-muted;
                margin-bottom: 5px;

                // ellipsis на второй строке
                display: -webkit-box;
                text-overflow: ellipsis;
                overflow: hidden;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }

            &__stats-link {
                @include dashed-link($body-color, $link-hover-color);
                font-size: 14px;
                line-height: 16px;
                position: relative;

                &.active {
                    @include dashed-link($primary, $primary);

                    &::after {
                        display: inline-block;
                        content: '';
                        background: url(/img/icons/icon-close.svg) no-repeat center;
                        width: 15px;
                        height: 15px;
                        position: absolute;
                        right: -20px;
                        top: 1px;
                    }
                }
            }

            &__stats {
                display: none;
                position: relative;
                top: -5px;
                padding-bottom: 5px;

                &.showed {
                    display: block;
                }
            }
        }
    }
}
