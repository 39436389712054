.player-rating {
    margin-left: 0.25em;
    color: $primary;
    font-weight: normal;
    white-space: nowrap;

    &::before {
        content: "\2605";
        font-size: smaller;
        margin-right: 0.1em;
    }

    &--no-decoration {
        &::before {
            display: none;
        }
    }

    &--block {
        display: block;
        margin-left: 0;
    }

    &__num-rates {
        color: #e0e7ec;
        &--num {
            color: $text-muted;
            a {
                color: $text-muted;
            }
        }
    }
}
