.page-review {

    &__block {
        max-width: 850px;
        margin-left: auto;
        margin-right: auto;
    }

    .card {
        .timer, .price {
            font-weight: bold;
            font-size: larger;
        }

        .price {
            color: $danger;
        }

        .discount {
            font-weight: bold;
            color: $primary;
        }
    }

}
