.page-home {
    .intro {
        margin-top: 13px;
    }
    .invitation {
        margin-top: 15px;
    }
    .connection {
        margin-top: 18px;
    }
    .game-types {
        margin-top: 36px;
    }
    .schedule--mobile {
        margin-top: 52px;
    }
    .home-tournament {
        margin-top: 18px;
    }
    .partners {
        margin-top: 24px;
    }
    .reviews {
        // margin-top: 8px;
        margin-top: 28px;
    }
    .home-enrollment {
        margin-top: 23px;
    }
    .home-promo-game {
        margin-top: 36px;
    }
    .footer {
        margin-top: 18px;
    }

    @include media-breakpoint-up(sm) {
        .intro {
            margin-top: 25px;
        }
        .invitation {
            margin-top: 40px;
        }
        .connection {
            margin-top: 28px;
        }
        .game-types {
            margin-top: 46px;
        }
        .home-stats {
            margin-top: 57px;
        }
        .home-tournament {
            // margin-top: 82px;
            margin-top: 42px;
        }
        .reviews {
            margin-top: 49px;
        }
        .partners {
            margin-top: 43px;
        }
        .home-enrollment {
            margin-top: 43px;
        }
        .home-promo-game {
            margin-top: 40px;
        }
        .footer {
            margin-top: 43px;
        }
    }
}
