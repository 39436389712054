.review-card {
    @include media-breakpoint-up(sm) {
        padding: 24px 35px 39px 35px;
    }

    &__author {
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;

        &__info {
            padding-top: 7px;
        }

        &__name {
            color: #000000;
            font-family: $condensed-font;
            font-size: 20px;
            @include media-breakpoint-up(sm) {
                font-size: 24px;
                line-height: 28px;
            }
        }

        &__description {
            color: $text-muted;
            margin-top: 3px;
        }

        &__avatar {
            @include media-breakpoint-up(sm) {
                padding-left: 30px;
                padding-right: 10px;
                padding-top: 10px;
                margin-bottom: 9px;
            }
            img {
                max-width: 70px;
                max-height: 70px;
                border-radius: 35px;
                @include media-breakpoint-up(sm) {
                    max-width: 85px;
                    max-height: 85px;
                    border-radius: 43px;
                }
            }
        }
    }
    &__review {
        margin-top: 10px;

        @include media-breakpoint-up(sm) {
            font-size: 16px;
            line-height: 19px;
        }

        p:last-child {
            margin-bottom: 0;
        }
    }

    .show-all {
        @include dashed-link($primary, $link-hover-color);
        margin-left: 5px;
    }


}
