.league-selector {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-bottom: 7px;

    &--desktop {
        margin-top: 8px;
        margin-bottom: 27px;
        display: none;
    }
    @include media-breakpoint-up(sm) {
        &--mobile {
            display: none;
        }
        &--desktop {
            display: flex;
        }
    }

    &--dark {
        color: $home-tournament-dim-color;
        &.league-selector--desktop {
            .league-selector__label {
                font-weight: bold;
                text-transform: uppercase;
            }
        }
        .league-selector__nav-pills {
            a.nav-link {
                color: $home-tournament-dim-color;
                background: transparent;

                span {
                    color: white;
                    @include dashed_link(white, white);
                }

                &.active {
                    background: $light-nav-bg;
                    span {
                        color: $body-color;
                        border-bottom: none;
                    }
                }
            }
        }
    }

    .info-button {
        margin-left: 5px;
        @include media-breakpoint-up(sm) {
            margin-left: 5px;
        }
    }

    &__control {
        flex-grow: 1;
        margin-left: 10px;
        margin-right: -15px;
        height: 31px;

        overflow: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
        }

        @include media-breakpoint-up(sm) {
            margin-left: 30px;
            margin-right: 0;
        }

        .nav {
            flex-wrap: nowrap;
        }
        .nav-item {
            white-space: nowrap;
        }
    }

    &--centered {
        justify-content: center;
        .league-selector__control {
            flex-grow: 0;
        }
    }

    &__nav-pills {
        a.nav-link {
            margin-right: 10px;
            padding: 7px 10px;
            color: $text-muted;
            background: $light-nav-bg;

            span {
                color: black;
            }
            &.active {
                color: $text-muted;
                span {
                    color: $link-color;
                    border-bottom: none;
                }
            }

            @include media-breakpoint-up(sm) {
                padding: 7px 16px;
                margin-right: 0;
                background: transparent;

                span {
                    @include dashed_link(black, $link-color);
                }
            }
        }
    }
}
