.place-change {
    font-weight: normal;
    &--up {
        color: #6d9959 !important;
        &::after {
            content: "\2191";
            font-family: $font-family-sans-serif;
        }
    }
    &--down {
        color: #ff596d !important;
        &::after {
            content: "\2193";
            font-family: $font-family-sans-serif;
        }
    }
}
