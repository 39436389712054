.home-promo-game {
    background: linear-gradient(180deg, #1d2468 0%, #030728 60%);
    border-radius: 12px;
    padding: 20px 5px 30px 5px;

    @include media-breakpoint-up(sm) {
        padding: 40px 43px 30px 70px;
        min-height: 261px;
        display: flex;
        // align-items: center;
        justify-content: space-between;

        background-color: #1d2468;
        background-image: url(/img/promo-game-bg.jpg);
        background-size: cover;
    }

    &__title {
        @include media-breakpoint-up(sm) {
            display: flex;
        }
    }

    &__month {
        @include media-breakpoint-up(sm) {
            position: relative;
            padding-left: 10px;
            margin-left: 10px;
            &::after {
                content: "";
                position: absolute;
                left: 0;
                top: 6px;
                bottom: 6px;
                border-left: 2px solid white;
            }
        }
    }

    &__participation {
        @include media-breakpoint-up(sm) {
            min-width: 630px;
            width: 630px;

            margin-left: 45px;
        }
    }

    &__participants {
        max-width: 320px; // на мобиле четыре аватара в строке
        margin-left: auto;
        margin-right: auto;
        @include media-breakpoint-up(sm) {
            max-width: 100%;
            position: relative;
            top: -4px;
        }
    }

    &__participant {
        padding: 4px;
        &--mobile-hidden {
            display: none;
        }
        @include media-breakpoint-up(sm) {
            &--mobile-hidden {
                display: block;
            }
            &--desktop-hidden {
                display: none;
            }
        }
        .avatar {
            display: block;
            border: 2px solid #f1f6f7;
            border-radius: 50%;
            img {
                width: 58px;
                height: 58px;
            }
        }
    }

    .more-participants-button {
        margin: 4px;
        box-shadow: 0 4px 10px 10px rgba(202, 164, 203, 0.05);
    }
}
