.home-stats {

    &__total-stats {
        margin: 40px 0;
        display: flex;
        justify-content: center;
        text-align: center;
        text-transform: uppercase;

        font-family: $condensed-font;
        font-weight: bold;
    }

    &__cell {
        width: 33%;
        font-weight: 700;
        font-size: 14px;
        line-height: 23px;
        a {
            text-decoration: none;
            color: $body-color;
            &:hover {
                color: $primary;
            }
        }
    }

    &__number {
        font-weight: 700;
        font-size: 28px;
        line-height: 45px;
        color: $primary;
    }

    &__russia {
        color: $text-muted;
        font-family: $font-family-sans-serif;
        font-weight: normal;
        text-transform: none;
    }

    @include media-breakpoint-up(sm) {
        margin: 0;

        &__cell {
            width: 290px;
            font-size: 20px;
        }

        &__number {
            font-size: 64px;
            line-height: 75px;
        }

        &__russia {
            font-size: smaller;
        }
    }
}
