.attachment {
    margin-bottom: 10px;
    display: flex;
    align-items: center;

    &:last-child {
        margin-bottom: 0;
    }

    &__icon {
        min-width: 48px;
        min-height: 32px;
    }
}
