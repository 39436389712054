.games {
    padding-top: 1px;

    .form-select {
        margin-bottom: 14px;
    }

    .nav {
        justify-content: center;
        margin-bottom: 20px;

        .nav-link {
            font-size: 16px;
            line-height: 19px;
            padding: 0.4rem 1rem;

            span {
                @include dashed-link($link-color, $link-hover-color);
            }

            &.active {

                span {
                    color: $body-color;
                    border: none;
                }
            }
        }
    }

    &__card {
        background: $white;
        position: relative;
        overflow: auto;
        padding-bottom: 13px;
        margin-bottom: 17px;
        border-bottom: 1px solid $border-on-white-color;

        &:last-child {
            border-bottom: none;
            margin-bottom: 0;
        }

        &__photos {
            margin-top: 10px;
            display: flex;
            @include overflow-no-scrollbar();

            span {
                margin-right: 10px;
                max-width: 113px;

                img {
                    min-width: 100%;
                }
            }
        }

        &__image {
            width: 50px;
            margin-right: 12px;
            float: left;

            &::after {
                display: block;
                content: '';
            }

            img {
                width: 100%;
                height: auto;
            }
        }

        &__header {
            padding-top: 5px;
            min-height: 45px;

            h3 {
                font-size: 20px;
                line-height: 23px;
                margin-bottom: 3px;
            }

            span {
                font-size: 14px;
                line-height: 16px;
                color: $text-muted;
            }
        }

        &__description {
            margin-top: 14px;
            clear: both;
            white-space: pre-wrap;
        }

        &__rules {
            text-align: center;
            margin: 15px 0 5px 0;
        }
    } 
        

    @include media-breakpoint-up(sm) {
        padding-top: 0;

        &__card {
            padding: 35px 45px 33px 45px;
            margin-bottom: 20px;
            @include border-with-shadow;

            &__photos {
                display: block;
                overflow: initial;

                span {
                    margin-right: 0;
                    max-width: none;
                }
            }

            &__image {
                width: 280px;
            }

            &__details {
                margin-left: 325px;
            }

            &__header {
                h3 {
                    font-size: 32px;
                    line-height: 37px;
                    margin-bottom: 0;
                }

                span {
                    font-size: 24px;
                    line-height: 28px;
                    font-family: $condensed-font;
                }
            }

            &__description {
                font-size: 16px;
                line-height: 19px;
                clear: initial;
                margin-top: 2px;
            }

            &__photos {
                margin-top: 15px;

                picture {
                    margin: 0 10px 10px 0;
                    display: inline-block;
                    cursor: pointer;
                }

                img {
                    height: 82px;
                    width: auto;
                }
            }

            &__rules {
                text-align: left;
                margin-top: 14px;

                .btn {
                    padding: 0.5rem 1.9rem;
                }
            }
        }
    }
}
