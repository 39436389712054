.landing-hosts {
    display: none;
    @include media-breakpoint-up(sm) {
        display: block;
    }

    &__heading {
        text-transform: uppercase;
        font-size: 32px;
        font-weight: bold;
        text-align: center;
        a {
            text-decoration: none;
            @include dashed-link($primary, $link-hover-color);
        }
    }

    .hosts {
        margin-top: 30px;
    }
}
