.home-contacts {
    padding: 30px 0;
    @include media-breakpoint-up(sm) {
        padding-bottom: 50px;
    }

    h3 {
        text-align: center;
        text-transform: uppercase;
        @include media-breakpoint-up(sm) {
            font-size: 32px;
            font-weight: bold;
        }
    }

    &__cells {
        padding-top: 20px;
        text-align: center;
        @include media-breakpoint-up(sm) {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-top: 0;
            padding-left: 20px;
            padding-right: 20px;
            text-align: left;
        }

        > div {
            padding-bottom: 20px;
            @include media-breakpoint-up(sm) {
                padding-bottom: 0;
            }
        }
    }

    &__avatar {
        img {
            width: 98px;
            height: 98px;
            border-radius: 50%;
        }
    }

    &__name-social {
        position: relative;
        flex-grow: 1;
        @include media-breakpoint-up(sm) {
            padding-left: 23px;
        }
    }

    &__inn {
        margin-top: 10px;
        @include media-breakpoint-up(sm) {
            position: absolute;
            left: 23px;
            bottom: -70px;
            color: $text-muted;
        }
    }

    &__name {
        font-family: $condensed-font;
        font-size: 20px;
        color: $primary;
        @include media-breakpoint-up(sm) {
            font-size: 24px;
        }
    }

    &__social {
        color: $text-muted;
        a {
            margin-left: 4px;
            white-space: nowrap;
            color: $text-muted;
            text-decoration: none;
            &:hover {
                color: $primary;
            }
            img {
                position: relative;
                top: -1px;
            }
        }
    }

    &__contact {
        color: $text-muted;
        padding-left: 30px;
        padding-right: 30px;
        a {
            display: block;
            font-family: $condensed-font;
            font-size: 20px;
            color: black;
            text-decoration: none;
            &:hover {
                color: $primary;
            }
            @include media-breakpoint-up(sm) {
                font-size: 24px;
            }
        }
        span {
            display: flex;
            align-items: center;
            justify-content: center;
            a {
                margin-left: 7px;
            }
            @include media-breakpoint-up(sm) {
                justify-content: normal;
            }
        }
        @include media-breakpoint-up(sm) {
            border-right: 1px solid #009df5;
            &:last-child {
                border-right: none;
                padding-right: 0;
            }
        }
    }
}
