@include media-breakpoint-up(sm) {
    :root {
        font-size: 16px;
    }
    .container-fluid {
        padding-right: var(--bs-gutter-x, 0.75rem);
        padding-left: var(--bs-gutter-x, 0.75rem);
    }
}

.container-fluid {
    max-width: $desktop-container-width + 15px + 15px;
    @include media-breakpoint-up(sm) {
        width: $desktop-container-width + 15px + 15px;
    }
    padding-right: 15px; // as in the design mockup
    padding-left: 15px;
}

.modal-backdrop {
    bottom: 0;
    right: 0;
    width: auto;
    height: auto;
}

.btn {
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
}

.btn-sm {
    font-size: 16px;
    font-weight: normal;
}

.btn-primary {
    color: #ffffff; // bootstrap's `constrast()` functions sets it to black
    &:hover,
    &:active,
    &:focus {
        color: #ffffff !important;
        background: $btn-primary-hover-bg;
    }
}

.btn-primary:disabled {
    //color: #dddddd;  // bootstrap's `constrast()` functions sets it to black
    color: #ffffff;
    background: #c1cbd0;
    border-color: #c1cbd0;
}

.btn-outline-danger {
    box-shadow: 0px 4px 16px rgba(220, 53, 69, 0.15);
    &:hover,
    &:active,
    &:focus {
        box-shadow: 0px 4px 16px rgba(220, 53, 69, 0.15);
    }
}

.btn-orange {
    color: #ffffff;
    background: $orange-color;
    box-shadow: 0px 4px 16px rgba(220, 53, 69, 0.15);
    &:hover,
    &:active,
    &:focus {
        color: #ffffff;
        background: $orange-hover-color;
        box-shadow: 0px 4px 16px rgba(220, 53, 69, 0.15);
    }
}

.text-preline {
    white-space: pre-line;
}

.text-prewrap {
    white-space: pre-wrap;
}

.text-bold {
    font-weight: bold;
}

.text-lg {
    font-size: 16px;

    @include media-breakpoint-up(sm) {
        font-size: 20px;
    }
}

.text-condensed {
    font-family: $condensed-font;
}

.font-weight-normal {
    font-weight: normal !important;
}

h1,
.h1 {
    font-size: 32px;
}

h2,
.h2 {
    font-size: 24px;
    font-weight: 700;
    @include media-breakpoint-up(sm) {
        font-size: 32px;
    }
}

h3,
.h3 {
    font-size: 20px;
}

.nav-pills .nav-link.active {
    outline: 0 !important;
}

.tooltip {
    .tooltip-inner {
        box-shadow: 0px 1px 16px rgba(0, 0, 0, 0.3);
    }
}

.popover {
    p:last-child {
        margin-bottom: 0;
    }
}
