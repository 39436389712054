.carousel-modal {
    .carousel-inner {
        height: 50vh;
        .carousel-item {
            height: 100%;
            img {
                height: 100% !important;
                object-fit: contain;
            }
        }

        @include media-breakpoint-up(sm) {
            height: 80vh;
        }
    }
    .carousel-control-prev, .carousel-control-next {
        width: 10%;
    }
    .carousel-control-prev-icon {
        background-image: url("/img/icons/icon-chevron-gallery-left.svg");
        background-size: 100%;
    }
    .carousel-control-next-icon {
        background-image: url("/img/icons/icon-chevron-gallery-right.svg");
        background-size: 100%;
    }
}
