.extra-info-points {
    &__formula {
        display: block;
        @include media-breakpoint-up(sm) {
            position: relative;
            width: 300px;
            top: 4px;
            left: -70px;
            font-family: $condensed-font;
        }
    }
}
