.page-photo-gallery {

    .flex-title {
        margin-top: 24px;
    }

    .photo-gallery {
        margin-top: 15px;
    }

    .footer {
        margin-top: 30px;
    }

    @include media-breakpoint-up(sm) {
        .photo-gallery {
            margin-top: 32px;
        }
        .flex-title {
            margin-top: 18px;
        }
        .footer {
            margin-top: 40px;
        }
    }
}
