.places-left-info {
    &__places {
        color: $primary;
        font-weight: bold;

        &--critical {
            color: $danger;
        }
    }
}
