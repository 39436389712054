.dronov-cup {
    display: block;
    text-decoration: none;
    text-align: center;
    color: $body-color;

    &:hover {
        color: $body-color;
    }

    @include media-breakpoint-up(sm) {
        padding: 8px 18px 7px 19px;
        border-radius: $card-border-radius;
        box-shadow: $card-box-shadow;
    }

    h3 {
        margin-top: 11px;
        margin-bottom: 0;
        color: $primary;
        font-weight: bold;
        text-transform: uppercase;
        @include media-breakpoint-up(sm) {
            font-size: 24px;
            line-height: 28px;
        }
    }

    &__first-panel {
        @include media-breakpoint-up(sm) {
            display: flex;
            align-items: center;
            justify-content: space-between;

            padding: 0 34px 17px 47px;
        }
    }

    &__cup {
        width: 60px;
        height: 60px;
        @include media-breakpoint-up(sm) {
            width: 85px;
            height: 85px;
            position: relative;
            top: 3px;
        }
    }

    &__description {
        @include media-breakpoint-up(sm) {
            text-align: left;
        }
    }

    &__subtitle {
        margin-bottom: 3px;
        font-size: 16px;
        font-weight: bold;
        @include media-breakpoint-up(sm) {
            font-family: $condensed-font;
            font-size: 20px;
            margin-bottom: 0;
        }
    }

    &__chevron-right {
        img {
            position: relative;
            top: 10px;
            width: 25px;
            height: 48px;
        }
    }

    &__leaders {
        // margin-top: 13px;
    }
}
