.faq {

    &__group {
        margin-bottom: 30px;

        h4 {
            font-family: $font-family-sans-serif;
            font-size: 16pt;

            @include media-breakpoint-up(sm) {
                font-size: 20pt;
            }
        }
    }

    &__question {

        padding-left: 25px;
        margin-top: 5px;
        font-size: larger;
        font-family: $condensed-font;

        a {
            text-decoration: none;
            border-bottom: 1px dashed #e5e5e5;
        }

        position: relative;
        &::after {
            position: absolute;
            left: 5px;
            top: 2px;
            content: url("/img/icons/icon-chevron-expand.svg");
        }

        &--expanded::after {
            position: absolute;
            left: 0px;
            top: 0px;
            content: url("/img/icons/icon-chevron-collapse.svg");
        }
    }

    &__answer {
        display: none;
        padding-left: 25px;
        margin-top: 10px;
        margin-bottom: 10px;

        &--expanded {
            display: block;
        }

        img {
            max-width: 100%;
            height: auto;
        }
    }
}
