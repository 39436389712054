$votes-scale-height: 8px;
$votes-scale-radius: 4px;

.votes-scale {
    display: inline-block;
    width: 100px;
    height: $votes-scale-height;
    border-radius: $votes-scale-radius;
    background: $danger;

    &__indicator {
        height: 100%;
        border-radius: $votes-scale-radius;
        background: $success;
    }
}
