.page-contacts {
    h2 {
        text-align: center;
        margin-top: 24px;
    }
    .contacts {
        margin-top: -6px;
    }
    .footer {
        margin-top: 20px;
    }

    @include media-breakpoint-up(sm) {
        h2 {
            margin-top: 18px;
        }
        .contacts {
            margin-top: -12px;
            margin-left: auto;
            margin-right: auto;
            max-width: 1045px;
        }
        .footer {
            margin-top: 50px;
        }
    }
}
