.hosts {
    &__card {
        margin-bottom: 20px;
        border-bottom: 1px solid $border-on-white-color;
        padding-bottom: 11px;

        &:last-child {
            border-bottom: none;
        }

        &__image {
            width: 120px;
            height: 120px;
            margin-right: 30px;
            float: left;

            &:after {
                display: block;
                content: "";
            }

            img {
                width: 100%;
                height: auto;
                border-radius: 50%;
            }
        }

        &__info {
            margin-top: 10px;

            &__header {
                min-height: 110px;
            }

            h3 {
                margin-bottom: 5px;
            }

            p {
                clear: both;
                margin-top: 10px;
            }
        }

        &__short-description {
            color: $text-muted;
        }

        &__full-description {
            white-space: pre-line;
            margin-bottom: 0;
        }

        @include media-breakpoint-up(sm) {
            //margin-bottom: 0;
            border-bottom: none;
            //padding-bottom: 0;

            &__image {
                width: 200px;
                height: 200px;
                margin-right: 0;
                margin-inline: auto;
                float: none;
            }

            &__info {
                margin-top: 0;
                font-size: 16px;
                line-height: 19px;
                text-align: center;

                &__header {
                    min-height: auto;
                }

                h3 {
                    font-size: 24px;
                    line-height: 28px;
                    margin-top: 20px;
                    margin-bottom: 3px;
                }

                p {
                    margin-top: 10px;
                }
            }
        }
    }
}
