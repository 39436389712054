.home-tournament {
    position: relative;

    background-color: #08071c; // Fallback for Safari?
    background-image: radial-gradient(100% 100% at 90% 40%, #97798755 0%, #08071c00 50%),
        radial-gradient(100% 100% at 5% 50%, #4c92d355 0%, #08071c00 50%),
        radial-gradient(100% 100% at 50% 90%, #a04cd355 0%, #08071c00 50%),
        radial-gradient(100% 93.42% at 50% 6.58%, #513665 0%, #08071c 100%);

    h3 {
        margin-bottom: 4px;
        text-transform: uppercase;
        color: #ffffff;
    }

    a {
        text-decoration: none;
    }

    &--mobile {
        padding: 10px 0 35px 0;
        text-align: center;
        //background: radial-gradient(100% 93.42% at 50% 6.58%, #513665 0%, #08071c 100%);

        table {
            margin-top: 11px;
            margin-left: 35px;
            margin-right: 15px;

            text-align: left;
            td {
                vertical-align: top;
                padding-bottom: 16px;

                .home-tournament__avatar {
                    margin-right: 6px;
                }

                .home-tournament__stats {
                    margin-top: 2px;
                }
            }
        }
    }

    &--desktop {
        // padding: 33px 0 70px 0;
        padding: 15px 0 40px 0;

        .home-tournament__rating-link {
            // margin-top: 29px;
        }
    }

    &__title {
        padding: 20px 0 7px 0;
        text-align: center;
        text-transform: uppercase;

        font-family: $condensed-font;
        font-weight: bold;
        font-size: 26px;
        color: #ffffff;

        @include media-breakpoint-up(sm) {
            // padding: 52px 0 29px 0;
            padding: 30px 0 10px 0;
            font-size: 32px;
        }

        a {
            color: $orange-color;
            text-decoration: underline;

            &:hover {
                color: #ffffff;
            }
        }
    }

    &__table {
        padding: 0 100px 0 169px;
        font-family: $condensed-font;
        text-align: center;
        table {
            width: 100%;
        }
        th {
            position: relative;
            top: 3px;
            padding: 0 22px 17px 22px;
            font-family: $font-family-sans-serif;
            font-size: 16px;
            text-transform: uppercase;
            white-space: nowrap;
            color: $home-tournament-dim-color;
            &.home-tournament__table__title {
                top: 0px;
                padding-left: 116px;
                text-align: left;
                font-family: $condensed-font;
                font-size: 20px;
                color: #ffffff;
            }
        }
        td {
            padding: 4px 0 6px 0;
            font-size: 20px;
            color: #ffffff;
        }

        .home-tournament__position {
            text-align: left;
            font-size: 24px;
            color: $orange-color;
        }
        .home-tournament__avatar {
            margin-left: 10px;
            margin-right: 13px;
        }
        .home-tournament__player {
            padding-left: 7px;
            padding-right: 60px;
            min-width: 430px;
            text-align: left;
        }
        .home-tournament__name,
        .home-tournament__level {
            font-size: 20px;
        }
        .home-tournament__description {
            font-family: $font-family-sans-serif;
            font-size: 14px;
        }
    }

    &__legend {
        text-align: center;
        color: $home-tournament-dim-color;
    }

    &__avatar {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 60px;
        height: 60px;
        border-radius: 30px;

        background: linear-gradient(180deg, #ffffff 0%, #c4c4c4 100%);
        box-shadow: 0px 4px 24px rgba(39, 131, 216, 0.35);

        &:hover {
            background: $orange-color;
        }

        img {
            width: 56px;
            height: 56px;
            border-radius: 28px;
        }
    }

    &__position {
        font-family: $condensed-font;
        font-weight: bold;
        font-size: 16px;
        color: $orange-color;
    }

    &__name {
        margin-bottom: 3px;
        font-family: $condensed-font;
        font-weight: bold;
        font-size: 16px;
        color: #ffffff;

        &:hover {
            color: $orange-color;
        }
    }

    &__level {
        margin-left: 5px;
        font-size: 16px;
        font-weight: bold;
        color: $home-tournament-dim-color;
    }

    &__description {
        margin-bottom: 3px;
        color: $home-tournament-dim-color;

        // ellipsis на второй строке
        display: -webkit-box;
        text-overflow: ellipsis;
        overflow: hidden;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    &__stats {
        color: #ffffff;
        span {
            color: $home-stats-light-color;
        }
    }

    &__change {
        &--up {
            color: #acff59 !important;
            &::after {
                content: "\2191";
                font-family: $font-family-sans-serif;
            }
        }
        &--down {
            color: #ff596d !important;
            &::after {
                content: "\2193";
                font-family: $font-family-sans-serif;
            }
        }
    }

    &__rating-link {
        margin-top: 3px;
        text-align: center;
    }
}
