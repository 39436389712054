.paginator {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding-left: 0;
    list-style: none;
}

.page-item {
    margin: 2px;
    .page-link {
        display: flex;
        justify-content: center;
        align-items: center;

        width: 32px;
        height: 32px;
        border-radius: 50%;

        @include media-breakpoint-up(sm) {
            width: 42px;
            height: 42px;
        }

        background: #f1f6f7;
        color: $body-color;
        text-decoration: none;

        &:hover {
            border: 1px solid $primary;
        }
    }

    &.active .page-link {
        background: $primary;
        color: white;
    }
}
