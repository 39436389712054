.file-link {
    picture {
        margin-right: 5px; 
    }
    a {
        text-decoration: none;
        border-bottom: 1px solid $primary;

        &:hover {
            color: $link-hover-color;
            border-color: $link-hover-color;
        }
    }
}
