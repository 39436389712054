.static-page {
    margin-bottom: 3rem;

    h1 {
        font-weight: 700;
        margin: 1rem 0 2rem;
        text-align: center;
    }

    &::after {
        content: "";
        clear: both;
    }

    &__image {
        margin: 0 auto 2rem auto;

        img {
            max-width: 100%;
            height: auto;
            border-radius: 0.5rem;
        }
        @include media-breakpoint-up(sm) {
            &--left {
                float: left;
                max-width: 45%;
                padding-right: 1rem;
                padding-bottom: 1rem;
            }
            &--right {
                float: right;
                max-width: 45%;
                padding-left: 1rem;
                padding-bottom: 1rem;
            }
        }
    }

    &__content {
        h2 {
            font-weight: normal;
            font-size: 26px;
            line-height: 30px;
        }
        h3 {
            font-weight: normal;
            font-size: 24px;
            line-height: 28px;
        }

        img {
            max-width: 100%;
            height: auto;
        }

        table {
            --border: 1px solid #c1cbd0;
            border-radius: 5px;

            border-spacing: 0;
            border-collapse: separate;
            border: var(--border);
            overflow: hidden;

            th {
                background: #f1f6f7;
                font-weight: normal;
            }

            th,
            td {
                padding: 0.5rem 1rem;
            }

            th:not(:last-child),
            td:not(:last-child) {
                border-right: var(--border);
            }

            > thead > tr:not(:last-child) > th,
            > thead > tr:not(:last-child) > td,
            > tbody > tr:not(:last-child) > th,
            > tbody > tr:not(:last-child) > td,
            > tfoot > tr:not(:last-child) > th,
            > tfoot > tr:not(:last-child) > td,
            > tr:not(:last-child) > td,
            > tr:not(:last-child) > th,
            > thead:not(:last-child),
            > tbody:not(:last-child),
            > tfoot:not(:last-child) {
                border-bottom: var(--border);
            }
        }
    }

    &__edit-btn {
        display: inline-flex;
        align-items: center;
        justify-content: center;

        width: 28px;
        height: 28px;
        border: 1px solid transparent;
        border-radius: 50%;
        background: $light-nav-bg;

        &:hover {
            border-color: $primary;
        }
    }
}
