a.disabled {
    pointer-events: none;
}

a.game-place {
    text-decoration: none;
}

.avatar img {
    border-radius: 50%;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}

.phone-number-input {
    .dropdown-toggle {
        font-weight: normal !important;
        background: transparent;
        &::after {
            margin-left: 0.25rem;
        }
    }
    .dropdown-item {
        font-size: 1.2rem;
    }
}

.player-level {
    white-space: nowrap;
    &--current {
        color: #00cc00;
    }
}

.info-button {
    display: inline-block;
    width: 18px;
    height: 18px;
    line-height: 18px;

    color: #ffffff;
    background: #c1cbd0;
    border-radius: 9px;

    text-align: center;
    text-decoration: none;
    font-family: $font-family-sans-serif;
    font-size: 14px;
    font-weight: bold;

    &:hover {
        color: #ffffff;
    }
}

.delete-button {
    all: unset;
    width: 18px;
    height: 18px;

    color: #ffffff;
    background: #c1cbd0;
    border-radius: 9px;

    text-align: center;
    text-decoration: none;
    font-family: $font-family-sans-serif;
    font-size: 14px;
    font-weight: normal;
}

.decrement-button,
.increment-button {
    display: inline-block;
    position: relative;
    width: 30px;
    min-width: 30px;
    height: 30px;
    padding: 0;
    border: 0;
    border-radius: 50%;
    background: $primary;
    &:hover {
        background: $btn-primary-hover-bg;
    }

    &::before {
        content: "";
        position: absolute;
        top: 14px;
        bottom: 14px;
        left: 25%;
        right: 25%;
        background: white;
    }
}

.increment-button::after {
    content: "";
    position: absolute;
    top: 25%;
    bottom: 25%;
    left: 14px;
    right: 14px;
    background: white;
}

ul.list-checkboxed {
    list-style: none;
    padding-left: 20px;
    li {
        position: relative;
        &::after {
            content: url("/img/icons/icon-check.svg");
            position: absolute;
            left: -20px;
            top: 0px;
        }
    }
}

.d-mobile-only {
    display: block !important;
    @include media-breakpoint-up(sm) {
        display: none !important;
    }
}

.d-desktop-only {
    display: none !important;
    @include media-breakpoint-up(sm) {
        display: block !important;
    }
}

.d-inline-mobile-only {
    display: inline !important;
    @include media-breakpoint-up(sm) {
        display: none !important;
    }
}

.d-inline-desktop-only {
    display: none !important;
    @include media-breakpoint-up(sm) {
        display: inline !important;
    }
}

.line-clamp {
    // ellipsis на второй строке
    display: -webkit-box;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.line-clamp-2 {
    -webkit-line-clamp: 2;
}

.line-clamp-3 {
    -webkit-line-clamp: 3;
}

.line-clamp-4 {
    -webkit-line-clamp: 4;
}

.line-clamp-5 {
    -webkit-line-clamp: 5;
}

.tiny {
    font-size: 0.8em;
}

.fit-content {
    width: fit-content;
    max-width: fit-content;
}

.bg-light-darker {
    background-color: #f1f6f7;
}

.table-sticky-column-1 {
    thead th:nth-child(1),
    tbody td:nth-child(1) {
        position: -webkit-sticky; /* for Safari */
        position: sticky;
        left: 0;
        z-index: 1;
        background-color: white;
    }
}

.table-sticky-column-2 {
    thead th:nth-child(2),
    tbody td:nth-child(2) {
        position: -webkit-sticky; /* for Safari */
        position: sticky;
        left: 0;
        z-index: 1;
        background-color: white;
    }
}

@mixin dashed-link($color, $hover-color: "") {
    color: $color;
    text-decoration: none;
    border-bottom: 1px dashed $color;
    &:hover {
        @if $hover-color == "" {
            color: $color;
        } @else {
            color: $hover-color;
            border-bottom: 1px dashed $hover-color;
        }
    }
}

.rounded-block {
    padding: 14px 20px 14px 17px;
    border: 1px solid $border-on-white-color;
    border-radius: 9px;
}

.rounded-block--shadow {
    box-shadow: $border-on-white-box-shadow;
}

.rounded-block--shadow-sm {
    box-shadow: $border-on-white-box-shadow-sm;
}

@mixin overflow-no-scrollbar() {
    overflow: scroll;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
    }
}

@mixin color-filter-white {
    filter: brightness(0.01) invert(99%) sepia(29%) saturate(126%) hue-rotate(254deg) brightness(114%) contrast(100%);
}

@mixin color-filter-primary {
    filter: invert(46%) sepia(80%) saturate(2136%) hue-rotate(173deg) brightness(96%) contrast(103%);
}

@mixin color-filter-green {
    filter: invert(65%) sepia(90%) saturate(399%) hue-rotate(66deg) brightness(99%) contrast(79%);
}

@mixin border-with-shadow() {
    border: 1px solid $border-on-white-color;
    box-shadow: $border-on-white-box-shadow;
    border-radius: 12px;
}
