.contact {
    display: grid;
    margin-top: 22px;
    padding-bottom: 7px;
    border-bottom: 1px solid #e0e7ec;

    grid-template-columns: 100px 1fr;
    grid-template-areas:
        "city city"
        "avatar person"
        "contacts contacts";

    @include media-breakpoint-up(sm) {
        grid-template-columns: 320px 1fr;
        grid-template-rows: auto auto 1fr;
        grid-template-areas:
            "avatar city"
            "avatar person"
            "avatar contacts";

        padding-bottom: 27px;
        border-bottom: none;
    }

    &__avatar {
        grid-area: avatar;
        display: grid;
        place-items: center;

        width: 90px;
        height: 90px;
        border-radius: 50%;
        background: white;
        box-shadow: 0 2px 6px rgba(101, 141, 177, 0.35);

        img {
            width: 86px;
            height: 86px;
            border-radius: 50%;
        }
        @include media-breakpoint-up(sm) {
            width: 269px;
            height: 269px;
            img {
                width: 261px;
                height: 261px;
            }
        }
    }

    &__city {
        grid-area: city;
        margin: 0 auto 17px auto;
        padding: 5px 12px;
        border-radius: 15px;
        font-weight: bold;
        color: white;
        background: $primary;
        text-transform: uppercase;
        @include media-breakpoint-up(sm) {
            margin: 40px auto 0 0;
            padding: 5px 16px;
        }
    }

    &__person {
        grid-area: person;
        padding-top: 4px;
    }

    &__name {
        margin-bottom: 3px;
        color: black;
        font-family: $condensed-font;
        font-size: 20px;
        @include media-breakpoint-up(sm) {
            font-size: 32px;
        }
    }

    &__description {
        color: $text-muted;
        @include media-breakpoint-up(sm) {
            margin-top: -3px;
            font-family: $condensed-font;
            font-size: 24px;
        }
    }

    &__contacts {
        grid-area: contacts;

        margin-top: 12px;
        @include media-breakpoint-up(sm) {
            display: flex;
            padding-top: 15px;
        }
    }

    &__contact {
        display: flex;
        flex-direction: column;
        margin-bottom: 14px;
        color: $text-muted;
        white-space: nowrap;

        @include media-breakpoint-up(sm) {
            height: 50px;

            padding-left: 30px;
            padding-right: 35px;
            border-right: 1px solid $primary;

            font-size: 16px;

            &:first-child {
                padding-left: 0;
            }

            &:last-child {
                border-right: none;
            }
        }

        a {
            font-family: $condensed-font;
            font-size: 20px;
            color: black;
            text-decoration: none;
            &:hover {
                color: $primary;
            }
            @include media-breakpoint-up(sm) {
                font-size: 24px;
            }
        }
    }

    &__number-label {
        a {
            display: inline-block;
            margin-top: -15px;
        }
        @include media-breakpoint-up(sm) {
            order: 1;
            padding-top: 3px;

            a {
                img {
                    width: 22px;
                    height: 22px;
                }
            }
        }
    }
}
