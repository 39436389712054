.modal {
    &--fancy {
        .modal-content {
            padding: 1rem 1.5rem;
        }
        .modal-header {
            border-bottom: none;
            .btn-close {
                position: absolute;
                right: 20px;
                top: 20px;
            }
        }
        .modal-title {
            width: 100%;
            text-align: center;
            color: $primary;
            font-weight: bold;
            font-size: 24px;
            @include media-breakpoint-up(sm) {
                font-size: 32px;
            }
        }
        .modal-body {
            text-align: center;
            padding: 10px 0 15px 0;
            .input-wrapper {
                max-width: 300px;
                width: 100%;
                margin: 0 auto 10px auto;
            }
            input[type="text"],
            input[type="tel"] {
                padding: 0.8rem 1rem;
            }
        }
        .modal-footer {
            border-top: none;
            padding: 0;
            justify-content: center;
            text-align: center;
            p {
                margin-top: 10px;
                font-size: 14px;
            }
        }

        @include media-breakpoint-up(sm) {
            .modal-dialog {
                max-width: 700px;
            }
            .modal-content {
                border-radius: 20px;
                padding: 53px 85px;
            }
            .modal-header {
                padding: 0;

                .btn-close {
                    position: absolute;
                    right: 20px;
                    top: 20px;
                    background: url(/img/icons/icon-close-lg.svg) no-repeat center;
                    width: 28px;
                    height: 28px;
                }
            }
            .modal-body {
                padding: 19px 0 0 0;
            }
            .modal-footer {
                button {
                    font-size: 24px;
                    padding: 13px 50px;
                }
            }
        }
    }

    &--tournament,
    &--grades-table {
        @include media-breakpoint-up(sm) {
            .modal-header {
                border-bottom: none;
                padding-top: 45px;
                padding-bottom: 8px;

                .modal-title {
                    margin: 0 auto;
                    font-weight: bold;
                    font-size: 24px;
                    line-height: 28px;
                }

                .btn-close {
                    position: absolute;
                    right: 40px;
                    background: url(/img/icons/icon-close-lg.svg) no-repeat center;
                    width: 28px;
                    height: 28px;
                }
            }

            .modal-body {
                padding: 1rem 6rem 5.4rem 6rem;
            }
        }
    }

    &--enrollment {
        .modal-title {
            .game-date {
                display: block;
                color: $body-color;
                font-weight: normal;
                font-size: 20px;
                @include media-breakpoint-up(sm) {
                    font-size: 24px;
                }
            }
        }
    }

    &--auth {
        .sms-code {
            input {
                margin: 0 3px;
                max-width: 44px;
            }
        }
    }

    .privacy-note {
        max-width: 350px;
        margin-left: auto;
        margin-right: auto;
    }

    &--qr {
        .modal-body {
            text-align: center;
            padding: 20px 10px 20px 10px;

            svg {
                width: 100%;
            }

            @include media-breakpoint-up(sm) {
                margin-top: 10px;
                padding: 40px 50px 80px 50px;
            }
        }
    }

    &--grades-table {
        @include media-breakpoint-up(sm) {
            .modal-header {
                padding-top: 35px;
            }
            .modal-body {
                padding: 2rem 2.3rem;
            }
        }
    }

    &--hostschedule {
        input.time {
            height: 38px !important;
            max-width: 80px !important;
        }
        select.form-select {
            height: 38px !important;
        }
    }
}
