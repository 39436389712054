.landing-features {
    @include media-breakpoint-up(sm) {
        display: flex;
        justify-content: space-between;
    }
}

.landing-feature {
    display: flex;
    width: 100%;
    padding: 10px 0;
    border-bottom: 1px solid $border-on-white-color;
    align-items: center;
    color: $home-tournament-dim-color;

    @include media-breakpoint-up(sm) {
        display: block;
        width: 100%;
        padding: 0 20px 0 20px;
        text-align: center;
        border-bottom: none;
        border-right: 1px solid $border-on-white-color;
        &:last-child {
            border-right: none;
        }
    }

    &__image {
        img {
            width: 50px;
            height: 50px;
            margin-right: 20px;
        }
        @include media-breakpoint-up(sm) {
            img {
                width: 80px;
                height: 80px;
                margin-right: 0;
                margin-bottom: 17px;
            }
        }
    }
}
