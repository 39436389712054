.landing-stats {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;

    @include media-breakpoint-up(sm) {
        display: flex;
        justify-content: center;
    }

    &__cell {
        text-align: center;
        @include media-breakpoint-up(sm) {
            padding: 0 15px;
        }
    }

    &__value {
        font-family: $condensed-font;
        font-weight: 700;
        font-size: 48px;
        color: $primary;
        @include media-breakpoint-up(sm) {
            font-size: 64px;
        }
    }

    &__label {
        text-transform: uppercase;
        font-weight: 700;
        font-size: 16px;
        @include media-breakpoint-up(sm) {
            font-family: $condensed-font;
            font-size: 20px;
        }
    }

    &__note {
        color: $text-muted;
    }
}
