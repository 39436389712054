.page-players {

    h2 {
        text-align: center;
        margin-top: 24px;
    }

    .players {
        margin-top: 24px;
    }

    @include media-breakpoint-up(sm) {
        .players {
            margin-top: 42px;

            &:first-of-type {
                margin-top: 37px;
            }
        }
        h2 {
            margin-top: 18px;
        }
        .footer {
            margin-top: 50px;
        }
    }
}
