.grades-table {
    width: 100%;

    tr {
        border-bottom: 1px solid #ececec;
    }

    tbody tr:last-child {
        border-bottom: 0;
    }

    th, td {
        text-align: center;
        vertical-align: middle;
        padding: 10px;
        border-right: 1px solid #ccc;

        &:last-child {
            border-right: 0;
        }
    }
    .col-lg-width {
        width: 160px;
        min-width: 160px;
    }
    .col-md-width {
        width: 110px;
        min-width: 110px;
    }
    .col-sm-width {
        min-width: 50px;
        
        &-fix {
            width: 50px;
        }
    }
}