// Custom variables

$condensed-font: "Roboto Condensed", Roboto, sans-serif;

$light-nav-bg: #f1f6f7;
$hover-nav-bg: #e0e7ec;

$dark-plate-bg: #221e2a;
$dark-plate-color: #ffffff;
$dark-plate-link-color: #ffffff;
$dark-plate-muted-color: #695b73;

$home-tournament-dim-color: #8b7e93;
$home-stats-light-color: #816094;
$orange-color: #fbb600;
$orange-hover-color: #eaa500;

$desktop-container-width: 1170px;

$btn-danger-bg: #bb2d3b;

$btn-primary-hover-bg: #00baf5;
$btn-danger-hover-bg: #bb2d3b;

$border-on-white-color: #e0e7ec;
$border-on-white-box-shadow: 0px 4px 24px rgba(164, 196, 203, 0.25);
$border-on-white-box-shadow-sm: 0px 0px 8px rgba(101, 141, 177, 0.15);
$border-primary-box-shadow: 0px 4px 24px rgba(90, 156, 255, 0.7);

$place-1-color: #fbb600;
$place-2-color: #cadae1;
$place-3-color: #e5c09d;
$not-paid-color: red;
$special-color: green;
$no-contact-color: black;
$host-color: orange;

// Bootstrap theming
$enable-shadows: true;
$enable-negative-margins: true;

$body-color: #484d4f;
$primary: #009df5;
$text-muted: #97a4aa;
$green-color: #52d753;

$font-family-sans-serif: Roboto, system-ui, -apple-system, "Segoe UI", "Helvetica Neue", Arial, "Noto Sans",
    "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-size-root: 14px;
$line-height-base: 1.15;

$link-shade-percentage: 30%;

$headings-line-height: 1.15;
$headings-font-family: $condensed-font;
$headings-font-weight: normal;
$headings-color: #000000;

$btn-font-family: $condensed-font;

$btn-padding-y: 0.6rem;
$btn-padding-x: 2.25rem;
$btn-padding-y-lg: 0.625rem;

$btn-border-radius: 27px;
$btn-border-radius-sm: 27px;
$btn-border-radius-lg: 27px;

$btn-focus-width: 0;
$btn-box-shadow: 0px 4px 16px rgba(37, 124, 255, 0.25);
$btn-focus-box-shadow: 0px 4px 16px rgba(37, 124, 255, 0.25);
$btn-active-box-shadow: 0px 4px 16px rgba(37, 124, 255, 0.25);

$input-color: #2a2532;
$input-placeholder-color: #a6a6a6; // $text-muted;
$input-padding-y-lg: 0.625rem;
$input-border-radius-lg: 6px;

$nav-pills-border-radius: 15px;
$nav-pills-link-active-color: $primary;
$nav-pills-link-active-bg: $light-nav-bg;

$card-border-width: 0;
$card-border-color: transparent;
$card-border-radius: 12px;
$card-box-shadow: 0px 4px 24px rgba(164, 196, 203, 0.25);
$card-spacer-y: 22px;
$card-spacer-x: 21px;

$popover-border-color: #d2e0e8;
$popover-box-shadow: 0px 1px 16px #d2e0e8;
$popover-max-width: 300px;

// $modal-backdrop-bg: #717683;

$offcanvas-horizontal-width: 284px;
$offcanvas-padding-y: 18px;
$offcanvas-padding-x: 36px;

$form-select-font-size: 18px !default;
$form-select-padding-x: 9px !default;
$form-select-padding-y: 6.5px !default;
$form-select-bg-size: 18px 29px !default;
$form-select-indicator-color: $text-muted !default;
$form-select-border-color: $text-muted !default;
$form-select-border-radius: 5px !default;
$form-select-box-shadow: unset !default;

$modal-lg: 1000px !default;
$modal-xl: 1170px !default;
$modal-dialog-margin-y-sm-up: 3.2rem !default;

$table-border-color: #dbe1e3 !default;

$tooltip-font-size: 14px;
$tooltip-color: $body-color;
$tooltip-bg: white;
$tooltip-border-radius: 5px;
$tooltip-opacity: 1;
$tooltip-padding-y: 7px;
$tooltip-padding-x: 15px;

@font-face {
    font-family: "Trajan Pro 3";
    src: local("Trajan Pro 3 Regular"), local("Trajan-Pro-3-Regular"),
        url("/fonts/TrajanPro3Regular.woff2") format("woff2"), url("/fonts/TrajanPro3Regular.woff") format("woff"),
        url("/fonts/TrajanPro3Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

// Configuration
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/utilities";

// Layout & components
@import "bootstrap/scss/root";
@import "bootstrap/scss/reboot";
@import "bootstrap/scss/type";
@import "bootstrap/scss/images";
@import "bootstrap/scss/containers";
@import "bootstrap/scss/grid";
@import "bootstrap/scss/tables";
@import "bootstrap/scss/forms";
@import "bootstrap/scss/buttons";
@import "bootstrap/scss/transitions";
@import "bootstrap/scss/dropdown";
// @import "bootstrap/scss/button-group";
@import "bootstrap/scss/nav";
// @import "bootstrap/scss/navbar";
@import "bootstrap/scss/card";
// @import "bootstrap/scss/accordion";
// @import "bootstrap/scss/breadcrumb";
// @import "bootstrap/scss/pagination";
// @import "bootstrap/scss/badge";
@import "bootstrap/scss/alert";
// @import "bootstrap/scss/progress";
// @import "bootstrap/scss/list-group";
@import "bootstrap/scss/close";
// @import "bootstrap/scss/toasts";
@import "bootstrap/scss/modal";
@import "bootstrap/scss/tooltip";
@import "bootstrap/scss/popover";
@import "bootstrap/scss/carousel";
// @import "bootstrap/scss/spinners";
@import "bootstrap/scss/offcanvas";
@import "bootstrap/scss/placeholders";

// Helpers
@import "bootstrap/scss/helpers";

// Utilities
@import "bootstrap/scss/utilities/api";

// Custom components and pages

@import "overwrite";
@import "misc";
@import "components/horizontal-scroller";
@import "components/paginator";
@import "components/dark-plate";
@import "components/header";
@import "components/footer";
@import "components/sidemenu";
@import "components/intro";
@import "components/home-carousel";
@import "components/invitation";
@import "components/connection";
@import "components/schedule-date-selector";
@import "components/schedule";
@import "components/promo-game";
@import "components/places-left-info";
@import "components/home-stats";
@import "components/home-custom-block";
@import "components/home-award";
@import "components/home-tournament";
@import "components/home-birthday-people";
@import "components/home-dronov-cup";
@import "components/partners";
@import "components/reviews";
@import "components/home-enrollment";
@import "components/home-promo-game";
@import "components/home-contacts";
@import "components/review-card";
@import "components/enrollment";
@import "components/enrollment-modal";
@import "components/profile";
@import "components/icons";
@import "components/players-table";
@import "components/games";
@import "components/tournament";
@import "components/tournament-award";
@import "components/tournament-stats";
@import "components/league-selector";
@import "components/game-type-selector";
@import "components/modal";
@import "components/rules-table";
@import "components/hosts";
@import "components/photo-gallery";
@import "components/payment";
@import "components/flex-title";
@import "components/place-change";
@import "components/progress";
@import "components/player-account-summary";
@import "components/document";
@import "components/static-page";
@import "components/contact";
@import "components/user-enrollments";
@import "components/game-types";
@import "components/embla";
@import "components/carousel-modal";
@import "components/players";
@import "components/file-link";
@import "components/bottom-menu";
@import "components/datepicker";
@import "components/player-report";
@import "components/faq";
@import "components/grades-table";
@import "components/unsubscribe";
@import "components/peerrating";
@import "components/player-rating";
@import "components/audit";
@import "components/attachment";
@import "components/landing-photos";
@import "components/landing-features";
@import "components/landing-stats";
@import "components/landing-hosts";
@import "components/corp-request";
@import "components/player-to-like";
@import "components/pills";
@import "components/host-level-pill";
@import "components/subscription-card";
@import "components/props-item";
@import "components/mafia-app";
@import "components/votes-scale";
@import "components/extra-info-points";
@import "components/more-participants-button";

@import "pages/home";
@import "pages/enrollment";
@import "pages/archive";
@import "pages/games";
@import "pages/tournament";
@import "pages/hosts";
@import "pages/player";
@import "pages/photo-gallery";
@import "pages/contacts";
@import "pages/review";
@import "pages/specialoffer";
@import "pages/players";
@import "pages/peerrating";
@import "pages/dating";
@import "pages/landing";
