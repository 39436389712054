.game-types {

    &__cards {
        margin-top: 17px;

        @include media-breakpoint-up(sm) {
            margin-top: 25px;
        }

        .game-types-card {
            min-width: 114px;
            text-align: center;

            @include media-breakpoint-up(sm) {
                min-width: 195px;
            }

            img {
                width: 72px;
                height: 72px;
                padding: 2px;
                border: 2px solid $white;
                border-radius: 50%;

                &:hover {
                    border: 2px solid $primary;
                }

                @include media-breakpoint-up(sm) {
                    width: 140px;
                    height: 140px;
                }
            }

            &__details {
                margin-top: 7px;
                span {
                    display: block;
                    font-weight: 700;
                    margin-bottom: 4px;
                }
                p {
                    margin-bottom: 0;
                    line-height: 18px;
                }

                &__link-rules {
                    margin-top: 3px;
                    display: inline-block;
                    font-size: 14px;
                    text-decoration: none;
                }
            }
        }

        .slide-left, .slide-right {
            position: absolute;
            height: 32px;
            width: 18px;
            top: 55px;
            opacity: 0.5;

            &:hover {
                opacity: 1;
                cursor: pointer;
            }

            &:after {
                display: block;
                width: 18px;
                height: 32px;
            }
        }

        .slide-left {
            left: 0;

            &:after {
                content: url("/img/icons/icon-chevron-gallery-left.svg");
            }
        }

        .slide-right {
            right: 0;

            &:after {
                content: url("/img/icons/icon-chevron-gallery-right.svg");
            }
        }
    }

    h3 {
        text-align: center;
        text-transform: uppercase;

        @include media-breakpoint-up(sm) {
            font-size: 32px;
            line-height: 37px;
        }
    }

    &--enrollment, &--archive {

        .game-types__cards {
            margin-top: 5px;
            padding-bottom: 5px;

            @include media-breakpoint-up(sm) {
                margin-top: 25px;
            }

            .game-types-card {
                min-width: 116px;

                @include media-breakpoint-up(sm) {
                    min-width: 195px;
                }

                img {
                    width: 98px;
                    height: 98px;

                    @include media-breakpoint-up(sm) {
                        width: 138px;
                        height: 138px;
                    }
                }

                &.active {
                    img {
                        border: 2px solid $primary;
                    }
                    .game-types-card__details {
                        padding: 6px 10px;
                        background-color: $light-nav-bg;

                        &__link {
                            color: $primary;
                            border: none;
                            &:hover {
                                border: none;
                            }
                        }
                    }
                }

                .game-types-card__details {
                    background-color: initial;
                    border-radius: 15px;
                    padding: 6px 0;

                    &__link {
                        font-size: 16px;
                        @include dashed-link($body-color, $link-hover-color);
                        @include media-breakpoint-up(sm) {
                            font-weight: bold;
                            border: none !important;
                        }
                    }
                }
            }
        }
    }

    .embla__button {
        top: 52px;
        width: 25px;
        height: 25px;

        @include media-breakpoint-up(sm) {
            top: 95px;
        }
    }
}
