.host-level-pill {
    position: relative;
    padding: 0.25rem 0.5rem;
    display: flex;
    align-items: center;
    white-space: nowrap;

    .info-button {
        position: relative;
        top: 0;
        left: 3px;
        margin-left: 2px;

        &:hover {
            color: $primary;
        }
    }

    &__popover {
        white-space: pre-wrap;
        font-size: 16px !important;
    }
}
