.player-report {
    padding-top: 20px;
    margin-bottom: 40px;

    .text-sm {
        font-size: 14px;
    }

    h3 {
        margin-top: 10px !important;
        margin-bottom: 30px;
    }

    table {
        width: 100%;
        font-size: 14px;

        a:hover {
            color: $primary;
        }

        .report-title {
            min-width: 235px;

            span {
                &:first-child {
                    vertical-align: top;
                }
                &:last-child {
                    max-width: 600px;
                }
            }
        }
    }

    .enrollment__card {
        padding: 14px 20px 14px 17px;
    }

    &__filter {
        text-align: center;
        margin-bottom: 20px;

        &__inputs {
            display: flex;
            justify-content: space-between;
        }
        .input-group--datepicker {
            margin-right: 0;

            &:first-child {
                margin-right: 5px;
            }
        }
        .btn {
            margin-top: 12px;
        }
    }

    &__friends-filter {
        display: flex;

        div {
            margin-right: 5px;
            min-width: 120px;
            display: inline-block;

            &:last-child {
                margin-right: 0;
            }

            a {
                text-decoration: none;
                font-size: 19px;

                &.active {
                    border-bottom: 2px solid;
                }
            }
        }
    }

    .player-reg {
        margin-bottom: 10px;
        font-size: 14px;
        color: $text-muted;
    }

    .row:last-child .players-table__participant {
        border-bottom: 0;
    }

    .players-table__participant {
        width: 230px;
    }

    @include media-breakpoint-up(sm) {
        h3 {
            margin-top: 29px !important;
            margin-bottom: 0.5rem;
        }
        .enrollment__card {
            padding: 55px 37px;
        }
        &__filter {
            text-align: left;
            display: flex;

            &__inputs {
                justify-content: initial;
            }
            .input-group--datepicker {
                margin-right: 20px;

                &:first-child {
                    margin-right: 20px;
                }
            }
            .btn {
                padding: 0.25rem 0.5rem;
                margin-top: 0;
            }
        }
        &__friends-filter {
            justify-content: initial;
            div {
                margin-right: 0;
                min-width: 150px;

                a {
                    font-size: 24px;
                    &.active {
                        border-bottom: 3px solid;
                    }
                }
            }
        }
        table {
            font-size: 16px;
        }
        .player-reg {
            margin-bottom: 0;
            font-size: 16px;
            color: $body-color;
        }
        .players-table__participant {
            border-bottom: 0;
            padding-bottom: 0;
            width: 270px;
        }
    }
}
