.enrollment {
    &__card__host--wrapper {
        // margin-left: 12px;
        padding-right: 40px;
        flex-grow: 1;
    }

    &__table {
        padding-right: 40px;
    }

    &__card {
        text-align: left;
        background: $white;
        padding: 55px 37px 55px 70px;
        margin-bottom: 10px;
        position: relative;
        font-size: 16px;
        line-height: 19px;
        @include border-with-shadow;

        .players-table {
            margin-top: 30px;
        }

        &__price,
        &__table {
            position: relative;
            width: 140px;
            line-height: 30px;
            background-color: $light-nav-bg;
            border-radius: 15px;
            display: inline-block;
            text-align: center;
        }

        &__table {
            height: 30px;
        }

        .info-button {
            line-height: 18px;
            position: absolute;
            right: 7px;
            top: 6px;
            outline: none;
        }

        &__price {
            padding-right: 15px;
            width: 164px;
            position: relative;
            left: -25px;

            &--profit {
                padding-top: 10px;
                line-height: 1.1;
                background: transparent;
            }
        }

        &__table {
            width: 127px;
            padding-right: 7px;

            .icon-check-mark::after {
                position: absolute;
                top: 6px;
                left: 4px;
            }
        }

        &__game-num-participants {
            font-size: 24px;
            line-height: 28px;
            color: $text-muted;
            font-family: $condensed-font;
        }

        &__game-description {
            white-space: pre-wrap;
        }

        &__game-feature {
            margin-top: -5px;
            margin-bottom: 10px;
            color: $text-muted;
        }

        &__host {
            display: flex;
            align-items: center;

            &__info {
                font-size: $font-size-root;
                text-align: left;
                line-height: 16px;
                margin-left: 14px;

                strong {
                    color: $black;
                }
            }

            img {
                width: 66px;
                height: 66px;
                border-radius: 33px;
            }

            &__club {
                img {
                    width: 20px;
                    height: 20px;
                    border-radius: 10px;
                }
            }

            a {
                background: $white;
                padding: 2px;
                border-radius: 50%;
                box-shadow: 0px 2px 6px rgba(101, 141, 177, 0.35);
                width: 70px;
                height: 70px;
            }
        }

        &__btn-enroll {
            margin-top: 13px;
        }

        &__header {
            justify-content: space-between;
            display: flex;
            align-items: center;
            padding-right: 35px;

            &__title {
                justify-content: space-between;
                display: flex;
                align-items: center;

                h1 a {
                    text-decoration: none;
                    white-space: nowrap;
                    &:hover {
                        color: $primary;
                        text-decoration: underline;
                    }
                }

                span {
                    font-size: 24px;
                    color: $text-muted;
                    font-family: $condensed-font;
                    margin: 0 30px 0 5px;
                    display: inline-block;
                    white-space: nowrap;
                }
            }

            &__address,
            &__game-type {
                font-size: $font-size-root;
                display: flex;
                align-items: center;

                span {
                    margin-right: 7px;
                }
            }

            &__game-type {
                white-space: nowrap;
                a {
                    @include dashed-link($body-color, $link-hover-color);
                    line-height: 1;
                }
            }
        }

        &__details {
            margin-top: 10px;
            align-items: center;
            display: flex;

            .btn.btn-wide-padding {
                padding: 0.9rem 2.5rem;
                font-size: 24px;
            }
        }
    }
}

.page-enrollment {
    .popover-body .crown-popover {
        text-align: center;
    }
    .enrollment-title {
        text-align: center;
        h2,
        a {
            display: inline-block;
        }
        a {
            margin-left: 0;
        }
    }
}
