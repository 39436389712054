.horizontal-scroller {
    --arrow-width: 40px;

    position: relative;

    &__arrow {
        position: absolute;
        top: 0;
        bottom: 0;
        z-index: 100;
        display: none;

        border: 0;
        padding: 0;
        width: var(--arrow-width);
        min-width: var(--arrow-width);

        background: rgb(255, 255, 255);

        &:hover img {
            filter: brightness(0);
        }

        &--left {
            left: 0;
            text-align: left;
            background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 70%);
        }
        &--right {
            right: 0;
            text-align: right;
            background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 70%);
        }

        @include media-breakpoint-up(sm) {
            &.visible {
                display: inline-block;
            }
        }
    }

    &__body {
        overflow: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
        }
        flex-grow: 1;
    }

    &__wrapper {
        position: relative;
        transition: left 500ms ease-out;
    }
}
