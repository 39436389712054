.connection {
    text-align: center;

    @include media-breakpoint-up(sm) {
        padding: 8px 18px 7px 19px;
        border-radius: $card-border-radius;
        box-shadow: $card-box-shadow;
    }

    h3 {
        margin-top: 11px;
        margin-bottom: 0;
        color: $primary;
        font-weight: bold;
        text-transform: uppercase;
        @include media-breakpoint-up(sm) {
            font-size: 24px;
            line-height: 28px;
        }
    }

    &__first-panel {
        @include media-breakpoint-up(sm) {
            display: flex;
            align-items: center;
            justify-content: space-between;

            padding: 0 64px 17px 47px;
        }
    }

    &__thumb-up {
        width: 60px;
        height: 60px;
        @include media-breakpoint-up(sm) {
            width: 85px;
            height: 85px;
            position: relative;
            top: 3px;
        }
    }

    &__description {
        @include media-breakpoint-up(sm) {
            text-align: left;
        }
    }

    &__offsite-events {
        margin-bottom: 3px;
        font-size: 16px;
        font-weight: bold;
        @include media-breakpoint-up(sm) {
            font-family: $condensed-font;
            font-size: 20px;
            margin-bottom: 0;
        }
    }

    &__chevron-right {
        img {
            position: relative;
            top: 10px;
            width: 25px;
            height: 48px;

        }
    }

    &__gallery {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-top: 13px;
    }

    &__photo {
        margin-bottom: 13px;
        img {
            width: 90px;
            height: 90px;
            border-radius: 12px;
            @include media-breakpoint-up(sm) {
                width: 124px;
                height: 124px;
                border-radius: 25px;
            }
        }
    }

    &__telegram {
        width: 100%;

        @include media-breakpoint-up(sm) {
            width: 124px;
            height: 124px;
        }

        a {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 auto;

            width: 190px;
            height: 42px;
            border-radius: 21px;
            // background: linear-gradient(190deg, #ffc700 5.85%, #ff1849 49.19%, #8900f5 90.27%);
            background-color: #009df5;

            text-decoration: none;

            img {
                width: 22px;
                height: 22px;
                margin-right: 7px;
            }

            span {
                font-family: $condensed-font;
                font-size: 20px;
                font-weight: bold;
                color: #ffffff;
            }

            @include media-breakpoint-up(sm) {
                width: 124px;
                height: 124px;
                border-radius: 21px;
                // background: linear-gradient(221.3deg, #ffc700 5.85%, #ff1849 49.19%, #8900f5 90.27%);
                background-color: #009df5;
                img {
                    width: 74px;
                    height: 74px;
                    margin-right: 0;
                }
                span {
                    display: none;
                }
            }
        }
    }
}
